import { SetStateAction, useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import DropDownPicker from "react-native-dropdown-picker";

interface DropdownProps {
  placeholder: string;
  value: string;
  setValue: (callback: SetStateAction<string>) => void;
  items: string[];
  onChangeValue?: (value: string) => void;
  onOpen?: () => void;
  onClose?: () => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  placeholder,
  value,
  setValue,
  items,
  onChangeValue,
  onOpen,
  onClose,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    DropDownPicker.modifyTranslation("EN", {
      PLACEHOLDER: "Wähle ein Element",
      SEARCH_PLACEHOLDER: "Tippe etwas...",
      SELECTED_ITEMS_COUNT_TEXT: {
        1: "Ein Element wurde ausgewählt",
        n: "{count} Elemente wurden ausgewählt",
      },
      NOTHING_TO_SHOW: "Es gibt nichts zu zeigen!",
    });
  }, []);

  return (
    <DropDownPicker
      placeholder={placeholder}
      open={open}
      setOpen={setOpen}
      multiple={false}
      value={value == "" ? null : value}
      setValue={setValue}
      items={items.map((item) => ({ label: item, value: item }))}
      style={styles.dropdown}
      containerStyle={styles.inputContainer}
      labelStyle={styles.label}
      placeholderStyle={styles.placeholder}
      zIndex={open ? 1000 : 0}
      onChangeValue={(value) => onChangeValue && onChangeValue(value!)}
      onOpen={onOpen ? onOpen : () => {}}
      onClose={onClose ? onClose : () => {}}
    />
  );
};

export default Dropdown;

const styles = StyleSheet.create({
  inputContainer: {
    width: "80%",
    maxWidth: 500,
    marginTop: 10,
  },
  label: {
    fontSize: 16,
  },
  placeholder: {
    fontSize: 16,
    color: "grey",
  },
  dropdown: {
    borderRadius: 10,
    backgroundColor: "white",
  },
  rowStyle: {
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
});

// changes in DropdownTheme Light
/*
export default StyleSheet.create({
    container: {
      width: "100%",
    },
    style: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      minHeight: 40,
      borderRadius: 8,
      borderWidth: 1,
      borderColor: "white",
      paddingHorizontal: 10,
      paddingVertical: 3,
      backgroundColor: "white",
    },
    label: {
      flex: 1,
      color: "black",
    },
    labelContainer: {
      flex: 1,
      flexDirection: "row",
    },
    arrowIcon: {
      width: 20,
      height: 20,
    },
    tickIcon: {
      width: 20,
      height: 20,
    },
    closeIcon: {
      width: 30,
      height: 30,
    },
    badgeStyle: {
      flexDirection: "row",
      alignItems: "center",
      borderRadius: 10,
      backgroundColor: "black",
      paddingHorizontal: 10,
      paddingVertical: 5,
    },
    badgeDotStyle: {
      width: 10,
      height: 10,
      borderRadius: 10 / 2,
      marginRight: 8,
      backgroundColor: "grey",
    },
    badgeSeparator: {
      width: 5,
    },
    listBody: {
      height: "100%",
    },
    listBodyContainer: {
      flexGrow: 1,
      alignItems: "center",
    },
    dropDownContainer: {
      position: "absolute",
      backgroundColor: "white",
      borderRadius: 8,
      borderColor: "white",
      borderWidth: 1,
      width: "100%",
      overflow: "hidden",
      zIndex: 1000,
    },
    modalContentContainer: {
      flexGrow: 1,
    },
    listItemContainer: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingHorizontal: 10,
      height: 40,
    },
    listItemLabel: {
      flex: 1,
      color: "black",
    },
    iconContainer: {
      marginRight: 10,
    },
    arrowIconContainer: {
      marginLeft: 10,
    },
    tickIconContainer: {
      marginLeft: 10,
    },
    closeIconContainer: {
      marginLeft: 10,
    },
    listParentLabel: {},
    listChildLabel: {},
    listParentContainer: {},
    listChildContainer: {
      paddingLeft: 40,
    },
    searchContainer: {
      flexDirection: "row",
      alignItems: "center",
      padding: 10,
      borderBottomColor: "black",
      borderBottomWidth: 1,
    },
    searchTextInput: {
      flexGrow: 1,
      flexShrink: 1,
      margin: 0,
      paddingHorizontal: 10,
      paddingVertical: 5,
      borderRadius: 8,
      borderColor: "black",
      borderWidth: 1,
      color: "black",
    },
    itemSeparator: {
      height: 1,
      backgroundColor: "black",
    },
    flatListContentContainer: {
      flexGrow: 1,
    },
    customItemContainer: {},
    customItemLabel: {
      fontStyle: "italic",
    },
    listMessageContainer: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      padding: 10,
    },
    listMessageText: {},
    selectedItemContainer: {},
    selectedItemLabel: {},
    modalTitle: {
      fontSize: 18,
      color: "black",
    },
    extendableBadgeContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
      flex: 1,
    },
    extendableBadgeItemContainer: {
      marginVertical: 3,
      marginEnd: 7,
    },
});
 */
