export const cachedStates = [
  { value: "Oberösterreich", id: "65QLoMJ8bWxUuRdSqIWj" },
  { value: "Steiermark", id: "5gMjcYgI5Ehd70rhcs6i" },
  { value: "Tirol", id: "F4vdJhMWKUB6hJgU5DAJ" },
  { value: "Niederösterreich", id: "NC5InpGKGzEz2nIR7eHD" },
  { value: "Wien", id: "NF53ZQssa8lvD4xGEihG" },
  { value: "Vorarlberg", id: "WJzkhhVCmcXWmTv9tf3u" },
  { value: "Kärnten", id: "jwpFHhrWuc3tB8JXuAgO" },
  { value: "Burgenland", id: "k0sjC73UwpmYQ4NvNhfl" },
  { value: "Salzburg", id: "nT7FUR6nV5t8rno17mo0" },
];

export const cachedIdToState = (id: string) => {
  const state = cachedStates.find((state) => state.id === id);
  if (state) {
    return state.value;
  }
  return "";
};

export const cachedStateToId = (value: string) => {
  const state = cachedStates.find((state) => state.value === value);
  if (state) {
    return state.id;
  }
  return "";
};

export const cachedSchools = [
  {
    value: "Nicht angeführt",
    id: "HQZaH9IB4GUyWYCTipxU",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Wels 3",
    id: "fKxJVsWmsQ0Wp6WJ269i",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Wels 1",
    id: "JUict9XdZNaVUUMftD7q",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Vöcklabruck-Gmunden",
    id: "tyGM5krys4S0VzGEbPzC",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Steyr 1",
    id: "rFYKQc3a6hDCptkuGgJA",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Schärding",
    id: "u7IJYbiRsKn7tZzYG2L6",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Rohrbach",
    id: "Dj6BU7VWaqyGzdvtBBuz",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Ried",
    id: "2JOVakR8CB5gRzVmucLL",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Mattighofen",
    id: "KHIg5LYmHgKSQZnLjG48",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Linz 10",
    id: "hVSUGBsIfKuQkw1wsHqI",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Linz 9",
    id: "UPNfZGBNhLZzd7u0gxSH",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Linz 8",
    id: "V3THhnddX8vfDbdAfLwQ",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Linz 7",
    id: "5JLZICrfgdP8OGxvrrMC",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Linz 6",
    id: "DyiQddFSrBdNttDlYHYb",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Linz 5",
    id: "wpy3TJZT2egEvHoOHMTY",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Linz 3",
    id: "wAQTpmi4cKD89p9HnK08",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Linz 2",
    id: "TU2NrhVnpCu7uum8dVwo",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Linz 1",
    id: "KW7D9ERaN5jCKOxwRxja",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Kremsmünster",
    id: "7sGRzmDmtpBGiZUp6IpP",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Gmunden 1",
    id: "VNmoMwylqHhfsgeh95VY",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Freistadt",
    id: "VzC1WXWEnnWCKAJpA4lr",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Attnang",
    id: "jdev4YglBEphUH9LLTVA",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Berufsschule Altmünster",
    id: "qFKfpTZOgJRvKsQ48WaX",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BG/BRG Braunau",
    id: "cKtnl4RJV3RXqRDedNz0",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BHAK Braunau",
    id: "iELAoMunYhG9fQguvOcl",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HTL Braunau",
    id: "5GZBN9G6g9JLaHxGHz6j",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HLW Braunau",
    id: "M2S5tj5vkOoJBpMMFAWd",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HAK Eferding",
    id: "SpTlE395ja3ykl39KgJy",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Gymnasium Dachsberg",
    id: "2rj9BYTv0gWgOcNyz4wc",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BG/BRG Freistadt",
    id: "9XkoWfmZVZlxOIVMXDgM",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HAK Freistadt",
    id: "qJma1JaNosRVKS3Ex7zX",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HTW Freistadt",
    id: "P4nnMe8dc2ZyKl4MVd9Y",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BRG Schloss Traunsee",
    id: "WlEliTUVeONK1JBRb1ay",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BG/BRG Gmunden",
    id: "jpUkhp9S9hjB91S2R3kf",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "ORG Ort",
    id: "2KmlUPueVh1A1b4ozrlY",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HAK Gmunden",
    id: "R4bmPtaIrLzvVsc1OJTI",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BR/BRG Bad Ischl",
    id: "58ZXI9yaLNRakKFiWxhM",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HAK/HAS Bad Ischl",
    id: "YpdU8Bwza3fvNmYbaAfH",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HLW Bad Ischl",
    id: "6T1ISxZV2qFiHpb3iDAC",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HBLA Ebensee",
    id: "jqkEWhimkcnWSyKAEgSB",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Tourismusschule Bad Ischl",
    id: "5KDzMyn9U4P296U6ypUv",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HTBLA Hallstatt",
    id: "F3Ozg90zKa8isrxgUk1V",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BORG Grieskirchen",
    id: "igAhiwAzc6IdNsIrz6b3",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HTL Grieskirchen",
    id: "d5U4l6vLiiUOPSNhlXVd",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BRG/BORG Kirchdorf",
    id: "dGqPVzUbNIRAbWNlvNRd",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HAK Kirchdorf",
    id: "yvy5rbRL8OP6RpzH9XLM",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Stiftsgymnasium Kremsmünster",
    id: "2N9iwM3Er9Ub0g7yp51B",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Gymnasium der Abtei Schlierbach",
    id: "pkpVHk8WzMp8uiRlZ4cb",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Akademisches Gymnasium Linz",
    id: "5xTXNMIHaIiAcLWpdss4",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Adalbert-Stifter-Gymnasium Linz",
    id: "Akw04Pb2Vr1pKrvqYURu",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Khevenhüller Gymnasium Linz",
    id: "weza4h2ollJZiEcNRfBF",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Europagymnasium Auhof",
    id: "IpBpIaUcYS5fpyoHAmES",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Linz International School Auhof",
    id: "7kD81DXyHOqwEnF9wo6M",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Bischöfliches Gymnasium Petrinum",
    id: "nJnQO10MKdKj6nB4ss6J",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Kollegium Aloisianum",
    id: "fmkWeWdIyzRXdCkd6Z9M",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Georg-von-Peuerbach-Gymnasium",
    id: "C4itK8cbXU0U1nMQoXfD",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BRG Landwiedstraße",
    id: "yUlCZEiD9S4ji66TPBnw",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HBLW Landwiedstraße",
    id: "rl5MWiOj79zGAuAC61MC",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HTL für künstlerische Gestaltung Linz",
    id: "8hTRyGcqudMwhNmL5EYa",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BRG Linz Fadingerstraße",
    id: "TOetoshTalIiFjptjhQe",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BG/BRG Linz Ramsauerstraße",
    id: "CoD3lSDZtyVw8sQcs19w",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Körnerschule Linz",
    id: "ZmuPAm1Xey9vpqK9MXca",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BORG Linz",
    id: "FJcOjno90yhrqCwWuoaW",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BRG solarCity Linz",
    id: "lyQoSNy8UUCikNvzXxC8",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HAK Linz",
    id: "l1RPqUkEomu9VFmM83LB",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Linzer Technikum",
    id: "1E0DBzhi1q2Uj1rUyldY",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HTL1 Bau und Design Linz",
    id: "reI8Vf33VzIpSS0ehWOK",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Schulzentrum Kreuzschwestern Linz",
    id: "NNuR2BZc6sAhTefbbJyO",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BAfEP Linz",
    id: "YfznDBNuJmTCzuHgqpb4",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BG/BRG Enns",
    id: "X8wnVgo0lLgjKdQNznXZ",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HTL Leonding",
    id: "IOyLHcqZuie2cbs2GMrI",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HLBLA St. Florian",
    id: "eDWfC6drmNPnYhiSJdeZ",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HAK Traun",
    id: "oFmerkNf5GbNyXcU8zOF",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BRG Traun",
    id: "JMCeMjGhohpjXZBzl3jP",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Stiftsgymnasium Wilhering",
    id: "uFYS8odckcWmsyCW0pbR",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Europagymnasium Baumgartenberg",
    id: "UIsgRx0W9oY2BSLvRihb",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HTL Perg",
    id: "pPOSAAkacWBPdJDCW99Y",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BG/BRG Ried",
    id: "oxltNc4pPpu2Xh3L2o8Z",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HTL Ried",
    id: "GK0rhT5gtgHqhsxT5XW0",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BG/BRG Rohrbach",
    id: "OZjY5YZAM64mcGVNbbPq",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HAK Rohrbach",
    id: "0YVvisaupT6F6b8P4XW6",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HTL Neufelden",
    id: "6cD1vR3ZTc154U7on59q",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HTL Andorf",
    id: "EqDcUoU8YHzFYJNGZrAa",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BG/BRG Schärding",
    id: "p8fKQKwb5eI1CL5F70aw",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BORG Schärding",
    id: "HlKBuv6u7ER7UvKMXaqh",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HAK Schärding",
    id: "cJiOHl95gGXYW5GYqhdP",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BG/BRG Steyr",
    id: "SQ5HkALbCHLzmmIOFp0d",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BRG Steyr",
    id: "6SXpOh47v2Cw3c0YLo5d",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HAK Steyr",
    id: "eLLABV46ck1ia7qpgiwl",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HTL Steyr",
    id: "jGnyYfrVV8NVFFr0uJVu",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Hotelfachschule Weyer",
    id: "WZ5gKOYr8HSCQabEABrk",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BORG Bad Leonfelden",
    id: "bygdeg1DeDZqvosuJLZC",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HTL Tourismus Bad Leonfelden",
    id: "F0OXaAW9gCigzvQ3gqhb",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BG Vöcklabruck",
    id: "RFaJ7gTSm2A3Sjd7mauV",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BRG Schloss Wagrain",
    id: "1PxdK3rUTpNxyQE0vHCe",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HAK Vöcklabruck",
    id: "QzsFBi4kW36IOj2zRHtR",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "Don Bosco Vöcklabruck",
    id: "gRtZYHBhxoS4rlEhUDMK",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HTL Vöcklabruck",
    id: "zhToiY9skGSqOrpTFy1l",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BG/BRG Wels Anton-Bruckner-Straße",
    id: "qdKdA5GbbkxgbNjSCYWb",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BG/BRG Wels Dr.-Schauer-Straße",
    id: "N4fxLG1yDCodMle27p9Q",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BRG Wels Wallererstraße",
    id: "rdyh0U7tRvN6qZUC8RZv",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HAK Wels",
    id: "j0oH1ecKYXHhgYLgEtXR",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HTL Wels",
    id: "FEX3pqQorYmR0OragkyL",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HTL für Lebensmitteltechnologie Wels",
    id: "YzUtT0LkAQxmQYQ6p2l5",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HTL für wirtschaftliche Berufe Wels",
    id: "YhTBMDTigSIDGgbfP27G",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "BRG Benediktinerstift Lambach",
    id: "QCaZNUEy7ZrVZHb6Yilw",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HAK Benediktinerstift Lambach",
    id: "Ss3pLN0vSCCNRooa4r5A",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HAK Perg",
    id: "UfSbu9xs4D8hwT5N4tsN",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
  {
    value: "HLW Perg",
    id: "0hSCtpcwshaYuM4qBVLN",
    stateId: "65QLoMJ8bWxUuRdSqIWj",
  },
];

export const cachedIdToSchool = (id: string) => {
  const school = cachedSchools.find((school) => school.id === id);
  if (school) {
    return school.value;
  }
  return "";
};

export const cachedSchoolToId = (value: string) => {
  const school = cachedSchools.find((school) => school.value === value);
  if (school) {
    return school.id;
  }
  return "";
};
