import React from "react";
import LSVView from "../../../components/LSVView";
import { useTabContext } from "../../TabContext";
import { Button } from "../../../components/Button";

const UserSipSeeMoreScreen = () => {
  const { sip } = useTabContext();
  
  return <LSVView header={sip!.title} full scroll>
    <Button title={sip!.date} variant="outline"/>
    <Button title={`${sip!.startTime} - ${sip!.endTime}`} variant="outline"/>
    <Button title={sip!.location} variant="outline"/>
    <Button title={sip!.description} variant="outline"/>
  </LSVView>;
};

export default UserSipSeeMoreScreen;
