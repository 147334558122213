import { useNavigation } from "@react-navigation/native";
import { StyleSheet, Text, View, Image } from "react-native";
import React, { useEffect } from "react";
import { User } from "firebase/auth";
import { auth } from "../../firebase";
import { Button } from "../../components/Button";

const WelcomeScreen = () => {
  const navigation = useNavigation();

  const handleLogin = () => {
    navigation.navigate("Login");
  };

  const handleSignup = () => {
    navigation.navigate("Signup");
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user: User | null) => {
      if (user) {
        navigation.navigate("Tabs");
      }
    });
    return unsubscribe;
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image source={require("../../assets/logo.png")} style={styles.image} />
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.titleText}>Willkommen!</Text>
        <Text style={styles.subtitleText}>
          Melde dich für das Abstimmungstool der SIPs an, um Anträge zu stellen
          und während der Sitzung abstimmen zu können.
        </Text>
      </View>
      <View style={styles.buttonContainer}>
        <Button title="Login" onPress={handleLogin} variant="filled" />
        <Button title="Registrieren" onPress={handleSignup} variant="outline"/>
      </View>
    </View>
  );
};

export default WelcomeScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
    padding: 20,
  },
  imageContainer: {
    width: 650,
    height: "50%",
    maxHeight: 650,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 50,
    paddingBottom: 50,
  },
  image: {
    resizeMode: "contain",
    width: "100%",
    height: "100%",
  },
  textContainer: {
    alignItems: "center",
    paddingBottom: 75,
  },
  titleText: {
    fontSize: 36,
    fontWeight: "bold",
    marginBottom: 10,
  },
  subtitleText: {
    fontSize: 16,
    textAlign: "center",
    color: "#666",
    maxWidth: 300,
    marginHorizontal: 20,
  },
  buttonContainer: {
    width: "100%",
    alignItems: "center",
    paddingBottom: 50,
  },
});

