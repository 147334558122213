import { Platform, ScrollView, StyleSheet, Text, View } from "react-native";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { useTabContext } from "../../TabContext";
import DraggableList from "../../../components/DraggableList";
import LSVView from "../../../components/LSVView";
import { Button } from "../../../components/Button";
import { collection, doc, getFirestore, setDoc } from "firebase/firestore";
import { LogType, log } from "../../../func/util";

const UserSipProposalsScreen = () => {
  const navigation = useNavigation();
  const { user, sip, proposals } = useTabContext();

  const [voted, setVoted] = useState<boolean>(false);
  const [secondPage, setSecondPage] = useState<boolean>(false);
  const [proposals1, setProposals1] = useState<string[]>([]);
  const [proposals2, setProposals2] = useState<string[]>([]);

  useEffect(() => {
    if (!sip!.isProposal) {
      navigation.navigate("UserSipActive");
    }
  }, [sip!.isProposal]);

  useEffect(() => {
    setProposals1(proposals.filter((p) => !p.standard).map((p) => p.title));
    setProposals2(proposals.filter((p) => p.standard).map((p) => p.title));
  }, [proposals]);

  const togglePage = () => {
    setSecondPage(!secondPage);
  };

  const sendProposals = async () => {
    const db = getFirestore();

    const combinedProposals = [...proposals1, ...proposals2];

    const updatePromises = combinedProposals.map(async (p, idx) => {
      const id = proposals.find((prop) => prop.title === p)!.id;
      const value = combinedProposals.length - idx;

      log("UserSipProposalsScreen", LogType.SET, `Ranked proposal ${id}`);
      return setDoc(
        doc(
          collection(db, "sip", sip!.id, "proposals", id, "ranking"),
          user!.id
        ),
        {
          value: value,
        }
      );
    });

    await Promise.all(updatePromises)
      .then(() => {
        setVoted(true);
      })
      .catch((error) =>
        log(
          "UserSipProposalScreen",
          LogType.ERROR,
          `User Sip Proposals: ${error}`
        )
      );
  };

  if (voted) {
    return (
      <LSVView header="Abgestimmt">
        <Text>Vielen Dank für deine Stimme!</Text>
      </LSVView>
    );
  }

  if (secondPage) {
    return (
      <UserSipProposalsPreScreen
        header="Allgemein"
        proposals={proposals2}
        setProposals={setProposals2}
        canContinue={false}
        togglePage={togglePage}
        sendProposals={sendProposals}
      />
    );
  }

  return (
    <UserSipProposalsPreScreen
      header="Geschäftsordnung"
      proposals={proposals1}
      setProposals={setProposals1}
      canContinue={true}
      togglePage={togglePage}
      sendProposals={sendProposals}
    />
  );
};

export default UserSipProposalsScreen;

const UserSipProposalsPreScreen: React.FC<{
  header: string;
  proposals: string[];
  setProposals: Dispatch<SetStateAction<string[]>>;
  canContinue: boolean;
  togglePage: () => void;
  sendProposals: () => void;
}> = ({
  header,
  proposals,
  setProposals,
  canContinue,
  togglePage,
  sendProposals,
}) => {
  return (
    <LSVView header={header} scroll={Platform.OS === "web"}>
      <Text>Reihe die Anträge</Text>
      <DraggableList
        data={proposals}
        setData={setProposals}
        keyExtractor={(s) => s}
      />
      {proposals.length === 0 && <Text>Keine derartigen Anträge</Text>}
      <View style={styles.buttonContainer}>
        <Button
          title={canContinue ? "Weiter" : "Zurück"}
          onPress={togglePage}
          variant="outline"
        />
        {!canContinue && (
            <Button
              title="Abschicken"
              onPress={sendProposals}
              variant="filled"
            />
          )}
        </View>
    </LSVView>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    height: 100,
    width: "100%",
    alignItems: "center",
    gap: 10,
    paddingTop: 40,
  },
});
