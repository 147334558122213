import { StyleSheet, Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import LSVView from "../../../components/LSVView";
import { Button } from "../../../components/Button";
import TextInput from "../../../components/TextInput";
import { useTabContext } from "../../TabContext";
import { collection, doc, getFirestore, updateDoc } from "firebase/firestore";
import { useNavigation } from "@react-navigation/native";
import { LogType, log } from "../../../func/util";

const UserSipVerifyScreen = () => {
  const navigation = useNavigation();
  const { user, sip, sipSignedUp } = useTabContext();

  const [code, setCode] = useState("");
  const [isProxy, setIsProxy] = useState(user?.isProxy || false);

  const verify = async () => {
    if (code !== sipSignedUp?.code) {
      alert("Der Code ist falsch!");
      return;
    }

    const db = getFirestore();
    log("UserSipVerifyScreen", LogType.SET, "Verifying");
    await updateDoc(
      doc(collection(db, "sip", sip!.id, "signUps"), sipSignedUp!.id),
      {
        verified: true,
      }
    ).then(() => {
      alert("Verifiziert!");
      navigation.goBack();
    });
  };

  const onProxy = async () => {
    setIsProxy(!isProxy);

    const db = getFirestore();
    log("UserSipVerifyScreen", LogType.SET, "Proxy");
    await updateDoc(
      doc(collection(db, "users"), user!.id),
      {
        isProxy: !isProxy,
      }
    );
  }

  return (
    <LSVView header="Verifizieren" scroll full>
      <Text style={styles.text}>
        Frage bitte bei dem Eintritt nach deinem Aktivierungscode, um dich
        verifizieren zu können.
      </Text>
      <TextInput value={code} onChangeText={setCode} />
      <View style={styles.button}>
        <Button title={isProxy ? "Vertretung" : "Keine Vertretung"} onPress={onProxy} variant={isProxy ? "filled" : "outline"} />
        <Button title="Senden" onPress={verify} variant="filled" />
      </View>
    </LSVView>
  );
};

export default UserSipVerifyScreen;

const styles = StyleSheet.create({
  text: {
    maxWidth: 300,
    marginBottom: 20,
    textAlign: "center",
  },
  button: {
    marginTop: 30,
    width: "100%",
    alignItems: "center",
  },
});
