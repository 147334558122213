import { ScrollView, StyleSheet, Text, View, ViewStyle } from "react-native";
import React, { ReactNode } from "react";
import { SafeAreaView } from "react-native-safe-area-context";

const LSVView: React.FC<{
  children: ReactNode;
  header: string;
  scroll?: boolean;
  gap?: number;
  full?: boolean;
}> = ({ children, header, scroll, gap, full }) => {
  const items: ViewStyle = {
    width: "100%",
    alignItems: "center",
    gap: gap || 5,
  };

  const inner = (
    <>
      <Text style={styles.header}>{header}</Text>
      <View style={items}>{children}</View>
    </>
  );

  const content = scroll ? (
    <ScrollView style={styles.scroll}>{inner}</ScrollView>
  ) : (
    inner
  );

  return full ? (
    <View style={styles.container}>{content}</View>
  ) : (
    <SafeAreaView style={styles.container}>{content}</SafeAreaView>
  );
};

export default LSVView;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  scroll: {
    width: "100%",
  },
  header: {
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
  },
});
