import { StyleSheet, Text, View } from "react-native";
import React from "react";
import LSVView from "../../../components/LSVView";
import TextInput from "../../../components/TextInput";
import { useTabContext } from "../../TabContext";
import {
  DocumentData,
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { Button } from "../../../components/Button";
import { LogType, log } from "../../../func/util";

const AdminSipVerifyScreen = () => {
  const { sip, user } = useTabContext();
  const [surname, setSurname] = React.useState("");
  const [users, setUsers] = React.useState<DocumentData[]>([]);
  
  const search = async () => {
    if (!sip) {
      alert("Keine Sip gefunden");
      return;
    }

    if (surname === "") {
      alert("Bitte Nachname eingeben");
      return;
    }

    setUsers([]);

    const db = getFirestore();

    log("AdminSipVerifyScreen", LogType.GET, "Search users");

    const _users = await getDocs(
      query(collection(db, "users"), where("surname", "==", surname))
    )
      .then((_users) => {
        return _users.docs
          .filter((doc) => doc.data().state === user!.state)
          .map((doc) => ({ ...doc.data(), id: doc.id, code: "" }));
      })
      .catch((error) => {
        log("AdminSipVerifyScreen", LogType.ERROR, error)
        alert("Fehler beim Laden der Daten");
      });

    if (!_users) {
      return;
    }

    setUsers(_users);
  };

  const showCode = async (user: DocumentData, index: number) => {
    if (!sip) {
      alert("Keine Sip gefunden");
      return;
    }

    if (!user.id) {
      alert("Keine User ID gefunden");
      return;
    }

    const db = getFirestore();

    log("AdminSipVerifyScreen", LogType.GET, "Show code");

    await getDocs(
      query(
        collection(db, "sip", sip.id, "signUps"),
        where("userId", "==", user.id)
      )
    )
      .then((docs) => {
        if (docs.empty) {
          return;
        }

        const _users = [...users];
        _users[index].code = docs.docs[0].data().code;
        setUsers(_users);
      })
      .catch((error) => {
        log("AdminSipVerifyScreen", LogType.ERROR, error)
      });
  };

  const userRow = (user: DocumentData, index: number) => {
    return (
      <View style={styles.studentContainer}>
        <Text style={styles.studentText}>
          {user.name} {user.surname}
        </Text>
        <Button
          title={user.code !== "" ? user.code : "Verifizierungscode anzeigen"}
          onPress={
            user.code !== ""
              ? () => {}
              : async () => await showCode(user, index)
          }
          variant="outline"
        />
      </View>
    );
  };

  return (
    <LSVView header="Verifizieren" full scroll gap={20}>
      <TextInput
        placeholder="Nachname"
        value={surname}
        onChangeText={setSurname}
      />
      <Button title="Suchen" onPress={search} variant="filled" />
      {users.map((user, index) => userRow(user, index))}
    </LSVView>
  );
};

export default AdminSipVerifyScreen;

const styles = StyleSheet.create({
  studentContainer: {
    width: "100%",
    alignItems: "center",
    maxWidth: 400,
    paddingHorizontal: 10,
    padding: 20,
    borderRadius: 10,
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "black",
    marginBottom: 20,
  },
  studentText: {
    fontSize: 18,
    fontWeight: "bold",
  },
});
