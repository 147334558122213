import { StyleSheet, Text, View } from "react-native";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTabContext } from "../../TabContext";
import LSVView from "../../../components/LSVView";
import { Button } from "../../../components/Button";
import TextInput from "../../../components/TextInput";
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import { useNavigation } from "@react-navigation/native";
import { LogType, log } from "../../../func/util";

const AdminSipProposalsScreen = () => {
  const navigation = useNavigation();
  const { sip } = useTabContext();

  const [secondPage, setSecondPage] = useState<boolean>(false);
  const [proposals1, setProposals1] = useState<string[]>([]);
  const [proposals2, setProposals2] = useState<string[]>([]);

  const togglePage = () => {
    setSecondPage(!secondPage);
  };

  const finish = async () => {
    if (proposals1.length === 0 && proposals2.length === 0) {
      alert("Bitte mindestens einen Antrag hinzufügen");
      return;
    }

    const db = getFirestore();

    const addProposal = async (proposal: string, isStandard: boolean) => {
      log("AdminSipProposalsScreen", LogType.SET, `Add proposal ${proposal}`);
      return await addDoc(collection(db, "sip", sip!.id, "proposals"), {
        title: proposal,
        standard: isStandard,
        voteResult: -1,
      });
    };

    try {
      await Promise.all([
        ...proposals1.map((proposal) => addProposal(proposal, false)),
        ...proposals2.map((proposal) => addProposal(proposal, true)),
      ]);

      log("AdminSipProposalsScreen", LogType.SET, "Set SiP active");

      await updateDoc(doc(collection(db, "sip"), sip!.id), {
        isActive: true,
        isProposal: true,
      });

      navigation.navigate("AdminSipActive");
    } catch (error) {
      log("AdminSipProposalsScreen", LogType.ERROR, error as string);
      alert("Fehler bei der Verarbeitung: " + error);
    }
  };

  if (!secondPage) {
    return (
      <AdminSipProposalsPreScreen
        header="Geschäftsordnung"
        proposals={proposals1}
        setProposals={setProposals1}
        canContinue={true}
        togglePage={togglePage}
        finish={finish}
      />
    );
  }
  return (
    <AdminSipProposalsPreScreen
      header="Allgemein"
      proposals={proposals2}
      setProposals={setProposals2}
      canContinue={false}
      togglePage={togglePage}
      finish={finish}
    />
  );
};

export default AdminSipProposalsScreen;

const AdminSipProposalsPreScreen: React.FC<{
  header: string;
  proposals: string[];
  setProposals: Dispatch<SetStateAction<string[]>>;
  canContinue: boolean;
  togglePage: () => void;
  finish: () => void;
}> = ({ header, proposals, setProposals, canContinue, togglePage, finish }) => {
  const [title, setTitle] = useState<string>("");

  const addProposal = () => {
    if (title === "") {
      alert("Bitte einen Titel eingeben");
      return;
    }

    setProposals([...proposals, title]);
    setTitle("");
  };

  const removeProposal = (index: number) => {
    setProposals(proposals.filter((_, i) => i !== index));
  };

  return (
    <LSVView header={header} full scroll>
      <View style={styles.inputContainer}>
        <TextInput
          value={title}
          onChangeText={setTitle}
          placeholder="Antrag Titel"
        />
        <Button title="Hinzufügen" onPress={addProposal} variant="filled" />
      </View>
      {proposals.map((proposal, index) => (
        <Button
          title={proposal}
          onPress={() => removeProposal(index)}
          variant="outline"
        />
      ))}
      {proposals.length === 0 && <Text>Keine Anträge</Text>}
      <View style={styles.buttonContainer}>
        <Button
          title={canContinue ? "Weiter" : "Zurück"}
          onPress={togglePage}
          variant="outline"
        />
        {!canContinue && (
          <Button title="Fertig" onPress={finish} variant="filled" />
        )}
      </View>
    </LSVView>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    paddingBottom: 40,
    alignItems: "center",
    gap: 10,
    width: "100%",
  },
  buttonContainer: {
    width: "100%",
    alignItems: "center",
    gap: 10,
    paddingTop: 40,
  },
});
