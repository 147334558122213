import uuid from "react-native-uuid";

export const generateSecureUID = () => {
  return uuid.v4().toString();``
};

export enum LogType {
  INFO = "INFO",
  GET = "GET",
  SET = "SET",
  ERROR = "ERROR",
}

const production = false;

export const log = (screen: string, type: LogType, msg?: string) => {
  if (production) return;

  const log = `${type} - ${screen}${msg ? ` - ${msg}` : ""}`;
  console.log(log);
};