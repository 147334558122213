import {
  FirebaseApp,
  FirebaseOptions,
  initializeApp,
  getApps,
  getApp,
} from "firebase/app";
import {
  initializeAuth,
  getReactNativePersistence,
  getAuth,
  Auth,
} from "firebase/auth";
import ReactNativeAsyncStorage from "@react-native-async-storage/async-storage";
import { Analytics, getAnalytics, isSupported } from "firebase/analytics";
import { Platform } from "react-native";

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyAjGDr8u88auL5f01Zhg9w72OnYMYwGMb4",
  authDomain: "lsv-app-d8f08.firebaseapp.com",
  projectId: "lsv-app-d8f08",
  storageBucket: "lsv-app-d8f08.appspot.com",
  messagingSenderId: "343738769385",
  appId: "1:343738769385:web:a4d2c96e25eb4167797578",
  measurementId: "G-CS1PJP8Y5G",
};

let app: FirebaseApp;
let _auth: Auth;
if (getApps().length === 0) {
  app = initializeApp(firebaseConfig);

  if (Platform.OS === "ios" || Platform.OS === "android") {
    _auth = initializeAuth(app, {
      persistence: getReactNativePersistence(ReactNativeAsyncStorage),
    });
  } else {
    _auth = getAuth(app);
  }
} else { 
  app = getApp();
  _auth = getAuth();
}
export const auth = _auth;

let analytics: Analytics | undefined;
isSupported().then((supported) => {
  if (supported) {
    analytics = getAnalytics(app);
  }
});
export { analytics };
