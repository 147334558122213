import {
  TouchableOpacity,
  StyleSheet,
  Text,
  StyleProp,
} from "react-native";

export interface ButtonProps {
  title: string;
  onPress?: () => void;
  onPressIn?: () => void;
  onPressOut?: () => void;
  variant?: "filled" | "outline";
  key?: string;
}

export type Button = React.FC<ButtonProps>;

function get<T>(
  filled: StyleProp<T>,
  outline: StyleProp<T>,
  variant?: string
): StyleProp<T> {
  if (variant === "filled") {
    return filled;
  } else {
    return outline;
  }
}

export const Button: Button = ({ title, onPress, onPressIn, onPressOut, variant, key }) => {
  return (
    <TouchableOpacity
      key={key && key?.length > 0 ? key : title}
      style={get(styles.filledButton, styles.outlineButton, variant)}
      onPress={onPress}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
    >
      <Text
        style={get(styles.filledButtonText, styles.outlineButtonText, variant)}
      >
        {title}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  filledButton: {
    backgroundColor: "black",
    marginTop: 10,
    width: "100%",
    maxWidth: 300,
    padding: 15,
    borderRadius: 10,
    alignItems: "center",
  },
  filledButtonText: {
    color: "white",
    fontSize: 16,
    textAlign: "center",
  },
  outlineButton: {
    backgroundColor: "white",
    marginTop: 10,
    borderColor: "black",
    borderWidth: 1,
    width: "100%",
    maxWidth: 300,
    padding: 15,
    borderRadius: 10,
    alignItems: "center",
  },
  outlineButtonText: {
    color: "black",
    fontSize: 16,
    textAlign: "center",
  },
});
