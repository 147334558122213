import { StyleSheet, View } from "react-native";
import React, { useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { useTabContext } from "../../TabContext";
import TextInput from "../../../components/TextInput";
import { Button } from "../../../components/Button";
import LSVView from "../../../components/LSVView";
import {
  collection,
  deleteDoc,
  doc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { LogType, log } from "../../../func/util";

const AdminEditSipScreen = () => {
  const navigation = useNavigation();
  const { sip, user } = useTabContext();

  const [sipTitle, setSipTitle] = useState<string>(sip?.title || "");
  const [sipDate, setSipDate] = useState<string>(sip?.date || "");
  const [sipStartTime, setSipStartTime] = useState<string>(
    sip?.startTime || ""
  );
  const [sipEndTime, setSipEndTime] = useState<string>(sip?.endTime || "");
  const [sipLocation, setSipLocation] = useState<string>(sip?.location || "");
  const [sipDescription, setSipDescription] = useState<string>(
    sip?.description || ""
  );

  const createSip = async () => {
    if (
      sipTitle === "" ||
      sipDate === "" ||
      sipStartTime === "" ||
      sipEndTime === "" ||
      sipLocation === "" ||
      sipDescription === ""
    ) {
      alert("Bitte alle Felder ausfüllen!");
      return;
    }

    const db = getFirestore();

    log("AdminEditSipScreen", LogType.SET, "Create SiP");

    return await setDoc(doc(collection(db, "sip")), {
      currentProposal: "",
      date: sipDate,
      description: sipDescription,
      done: false,
      endTime: sipEndTime,
      isActive: false,
      isProposal: false,
      isRequestEndOfDebate: "none",
      isRequestEndOfList: "none",
      isSpeakerList: false,
      isThreeQuestions: false,
      isType1: false,
      isType2: false,
      isVote: false,
      location: sipLocation,
      startTime: sipStartTime,
      state: user!.state,
      title: sipTitle,
      voteFor: "",
    })
      .then(() => {
        alert("SiP erfolgreich erstellt!");
      })
      .catch((error) => {
        alert(error);
      });
  };

  const updateSip = async () => {
    if (
      sipTitle === "" ||
      sipDate === "" ||
      sipStartTime === "" ||
      sipEndTime === "" ||
      sipLocation === "" ||
      sipDescription === ""
    ) {
      alert("Bitte alle Felder ausfüllen!");
      return;
    }

    const db = getFirestore();

    log("AdminEditSipScreen", LogType.SET, "Update SiP");

    return await updateDoc(doc(collection(db, "sip"), sip!.id), {
      title: sipTitle,
      date: sipDate,
      startTime: sipStartTime,
      endTime: sipEndTime,
      location: sipLocation,
      description: sipDescription,
    })
      .then(() => {
        alert("SiP erfolgreich geändert!");
        navigation.goBack();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const deleteSip = async () => {
    const db = getFirestore();

    log("AdminEditSipScreen", LogType.SET, "Delete SiP");

    return await deleteDoc(doc(collection(db, "sip"), sip!.id))
      .then(() => {
        alert("SiP erfolgreich gelöscht!");
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <LSVView header={!sip ? "SiP Erstellen" : "SiP Bearbeiten"} scroll full>
      <TextInput
        value={sipTitle}
        onChangeText={setSipTitle}
        placeholder="Titel"
      />
      <TextInput
        value={sipDate}
        onChangeText={setSipDate}
        placeholder="Datum"
      />
      <TextInput
        value={sipStartTime}
        onChangeText={setSipStartTime}
        placeholder="Startzeit"
      />
      <TextInput
        value={sipEndTime}
        onChangeText={setSipEndTime}
        placeholder="Endzeit"
      />
      <TextInput
        value={sipLocation}
        onChangeText={setSipLocation}
        placeholder="Ort"
      />
      <TextInput
        value={sipDescription}
        onChangeText={setSipDescription}
        placeholder="Beschreibung"
      />
      <View style={styles.button}>
        <Button
          title="Speichern"
          onPress={!sip ? createSip : updateSip}
          variant="filled"
        />
      </View>
      {sip && <Button title="Löschen" onPress={deleteSip} variant="outline" />}
    </LSVView>
  );
};

export default AdminEditSipScreen;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    alignSelf: "center",
    padding: 20,
  },
  innerContainer: {
    width: "100%",
    alignItems: "center",
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 30,
    alignSelf: "center",
  },
  button: {
    marginTop: 20,
    width: "100%",
    alignItems: "center",
  },
});
