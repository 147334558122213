import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import AdminHomeScreen from "./admin/AdminHomeScreen";
import UserHomeScreen from "./user/UserHomeScreen";
import AdminEditSipScreen from "./admin/AdminEditSipScreen";
import AdminSipVerifyScreen from "./admin/AdminSipVerifyScreen";
import AdminSipActiveScreen from "./admin/AdminSipActiveScreen";
import AdminSipProposalsScreen from "./admin/AdminSipProposalsScreen";
import UserSipSeeMoreScreen from "./user/UserSipSeeMoreScreen";
import UserSipVerifyScreen from "./user/UserSipVerifyScreen";
import UserSipActiveScreen from "./user/UserSipActiveScreen";
import UserSipProposalsScreen from "./user/UserSipProposalsScreen";
import AdminSipHoldScreen from "./admin/AdminSipHoldScreen";
import UserSipHoldScreen from "./user/UserSipHoldScreen";

export type RootHomeStackParamList = {
  AdminHome: undefined;
  AdminEditSip: undefined;
  AdminSipVerify: undefined;
  AdminSipActive: undefined;
  AdminSipHold: undefined;
  AdminSipProposals: undefined;
  UserHome: undefined;
  UserSipSeeMore: undefined;
  UserSipVerify: undefined;
  UserSipActive: undefined;
  UserSipHold: undefined;
  UserSipProposals: undefined;
};

declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootHomeStackParamList {}
  }
}

const HomeStack = createNativeStackNavigator<RootHomeStackParamList>();

const HomeScreen = () => {
  return (
    <HomeStack.Navigator>
      <HomeStack.Screen
        options={{
          headerShown: false,
          gestureEnabled: false,
          title: "SiP Hauptmenü",
        }}
        name="UserHome"
        component={UserHomeScreen}
      />
      <HomeStack.Screen
        options={{
          headerShown: true,
          gestureEnabled: true,
          title: "Mehr Erfahren",
        }}
        name="UserSipSeeMore"
        component={UserSipSeeMoreScreen}
      />
      <HomeStack.Screen
        options={{
          headerShown: true,
          gestureEnabled: true,
          title: "Verifizieren",
        }}
        name="UserSipVerify"
        component={UserSipVerifyScreen}
      />
      <HomeStack.Screen
        options={{
          headerShown: false,
          gestureEnabled: false,
          title: "SiP",
        }}
        name="UserSipActive"
        component={UserSipActiveScreen}
      />
      <HomeStack.Screen
        options={{ headerShown: false, gestureEnabled: false, title: "SiP" }}
        name="UserSipHold"
        component={UserSipHoldScreen}
      />
      <HomeStack.Screen
        options={{
          headerShown: false,
          gestureEnabled: false,
          title: "SiP Anträge",
        }}
        name="UserSipProposals"
        component={UserSipProposalsScreen}
      />
      <HomeStack.Screen
        options={{
          headerShown: false,
          gestureEnabled: false,
          title: "SiP Hauptmenü",
        }}
        name="AdminHome"
        component={AdminHomeScreen}
      />
      <HomeStack.Screen
        options={{
          headerShown: true,
          gestureEnabled: true,
          title: "SiP Bearbeiten",
        }}
        name="AdminEditSip"
        component={AdminEditSipScreen}
      />
      <HomeStack.Screen
        options={{
          headerShown: true,
          gestureEnabled: true,
          title: "SiP Verifizieren",
        }}
        name="AdminSipVerify"
        component={AdminSipVerifyScreen}
      />
      <HomeStack.Screen
        options={{ headerShown: false, gestureEnabled: false, title: "SiP" }}
        name="AdminSipActive"
        component={AdminSipActiveScreen}
      />
      <HomeStack.Screen
        options={{ headerShown: false, gestureEnabled: false, title: "SiP" }}
        name="AdminSipHold"
        component={AdminSipHoldScreen}
      />
      <HomeStack.Screen
        options={{
          headerShown: true,
          gestureEnabled: true,
          title: "SiP Anträge",
        }}
        name="AdminSipProposals"
        component={AdminSipProposalsScreen}
      />
    </HomeStack.Navigator>
  );
};

export default HomeScreen;
