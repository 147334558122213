import {
  Alert,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import React from "react";
import { useNavigation } from "@react-navigation/native";
import { useTabContext } from "../TabContext";
import Loading from "../../components/Loading";
import { Button } from "../../components/Button";
import { cachedIdToSchool, cachedIdToState } from "../../func/cached";
import { auth } from "../../firebase";
import { handleSignOut } from "../../func/signing";
import LSVView from "../../components/LSVView";

const ProfileScreen = () => {
  const navigation = useNavigation();
  const { user, isAdmin } = useTabContext();

  const onEdit = () => {
    // TODO: Implement
    if (Platform.OS === "web") {
      alert(`Bearbeiten des Profils ist derzeit nicht erhältlich.`);
    } else {
      Alert.alert(
        "Nicht verfügbar",
        `Bearbeiten des Profils ist derzeit nicht erhältlich.`
      );
    }
  };

  const onSignOut = () => {
    navigation.navigate("Welcome");
  };

  const onFail = (error: string) => {
    alert(error);
  };

  if (!user) {
    return <Loading />;
  }

  return (
    <LSVView header="Profil" scroll>
      <Button title={user.name} onPress={onEdit} variant="outline" />
      <Button title={user.surname} onPress={onEdit} variant="outline" />
      <Button title={user.position} onPress={onEdit} variant="outline" />
      <Button
        title={cachedIdToState(user.state)}
        onPress={onEdit}
        variant="outline"
      />
      <Button
        title={cachedIdToSchool(user.school)}
        onPress={onEdit}
        variant="outline"
      />
      <Button
        title={auth.currentUser!.email!}
        onPress={onEdit}
        variant="outline"
      />
      {isAdmin ? (
        <Button title="Admin" onPress={onEdit} variant="outline" />
      ) : (
        <Button
          title="Identität Prüfen"
          onPress={() => alert(user.id)}
          variant="outline"
        />
      )}
      <View style={styles.buttonContainer}>
        <Button
          title="Abmelden"
          onPress={() => handleSignOut(onSignOut, onFail)}
          variant="filled"
        />
      </View>
    </LSVView>
  );
};

export default ProfileScreen;

const styles = StyleSheet.create({
  buttonContainer: {
    flex: 1,
    width: "100%",
    paddingTop: 20,
    alignItems: "center",
    gap: 10,
    justifyContent: "flex-end",
  },
});
