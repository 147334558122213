import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import TextInput from "../../components/TextInput";
import { Button } from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import {
  User,
  UserCredential,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { doc, collection, getFirestore, setDoc } from "firebase/firestore";
import { auth } from "../../firebase";
import {
  cachedSchools,
  cachedStateToId,
  cachedStates,
} from "../../func/cached";
import LSVView from "../../components/LSVView";
import { LogType, log } from "../../func/util";

const SignupScreen = () => {
  const navigation = useNavigation();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  const [state, setState] = useState("");
  const [schools, setSchools] = useState<Map<string, string>>(new Map());
  const [schoolId, setSchoolId] = useState("");
  const [stateId, setStateId] = useState("");
  const [school, setSchool] = useState("");
  const [notFound, setNotFound] = useState(false);
  const [notFoundSchool, setNotFoundSchool] = useState("");
  const [position, setPosition] = useState("");

  const [page, setPage] = useState(false);
  const togglePage = () => {
    setPage(!page);
  };

  const handleRegister = () => {
    if (password !== rePassword) {
      alert("Passwörter stimmen nicht überein!");
      return;
    }

    if (
      position === "" ||
      name === "" ||
      surname === "" ||
      schoolId === "" ||
      stateId === ""
    ) {
      alert("Bitte alle Felder ausfüllen!");
      return;
    }

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential: UserCredential) => {
        const user = userCredential.user;
        log("SignupScreen", LogType.INFO, `Registered with: ${user.email}`);
        return addUserData();
      })
      .then(() => {
        log("SignupScreen", LogType.INFO, "User data added");
        navigation.navigate("Tabs");
      })
      .catch((error) => alert(error.message));
  };

  const addUserData = async () => {
    const user: User = auth.currentUser!;
    const db = getFirestore();

    log("SignupScreen", LogType.SET, "Adding user data");

    return await setDoc(doc(collection(db, "users"), user.uid), {
      name: name.trim(),
      surname: surname.trim(),
      school: schoolId,
      position: position,
      state: stateId,
    });
  };

  if (!page) {
    return SignupScreenPage1(
      togglePage,
      name,
      setName,
      surname,
      setSurname,
      email,
      setEmail,
      password,
      setPassword,
      rePassword,
      setRePassword
    );
  }

  return SignupScreenPage2(
    togglePage,
    handleRegister,
    position,
    setPosition,
    state,
    setState,
    school,
    setSchool,
    schools,
    setSchools,
    setSchoolId,
    setStateId,
    notFound,
    setNotFound,
    notFoundSchool,
    setNotFoundSchool
  );
};

export default SignupScreen;

const SignupScreenPage1 = (
  togglePage: () => void,
  name: string,
  setName: Dispatch<SetStateAction<string>>,
  surname: string,
  setSurname: Dispatch<SetStateAction<string>>,
  email: string,
  setEmail: Dispatch<SetStateAction<string>>,
  password: string,
  setPassword: Dispatch<SetStateAction<string>>,
  rePassword: string,
  setRePassword: Dispatch<SetStateAction<string>>
) => {
  return (
    <LSVView header="Schritt 1" scroll full>
      <Text>Information über den Account</Text>
      <TextInput value={name} onChangeText={setName} placeholder="Vorname" />
      <TextInput
        value={surname}
        onChangeText={setSurname}
        placeholder="Nachname"
      />
      <TextInput value={email} onChangeText={setEmail} placeholder="Email" />
      <TextInput
        value={password}
        onChangeText={setPassword}
        placeholder="Passwort"
        secureTextEntry
      />
      <TextInput
        value={rePassword}
        onChangeText={setRePassword}
        placeholder="Passwort bestätigen"
        secureTextEntry
      />
      <View style={styles.buttonContainer}>
        <Button title="Weiter" onPress={togglePage} variant="outline" />
      </View>
    </LSVView>
  );
};

const SignupScreenPage2 = (
  togglePage: () => void,
  handleRegister: () => void,
  position: string,
  setPosition: Dispatch<SetStateAction<string>>,
  state: string,
  setState: Dispatch<SetStateAction<string>>,
  school: string,
  setSchool: Dispatch<SetStateAction<string>>,
  schools: Map<string, string>,
  setSchools: Dispatch<SetStateAction<Map<string, string>>>,
  setSchoolId: Dispatch<SetStateAction<string>>,
  setStateId: Dispatch<SetStateAction<string>>,
  notFound: boolean,
  setNotFound: Dispatch<SetStateAction<boolean>>,
  notFoundSchool: string,
  setNotFoundSchool: Dispatch<SetStateAction<string>>
) => {
  const positionData = [
    "Schulsprecher:in",
    "1. Stellvertreter:in",
    "2. Stellvertreter:in",
    "Schüler:in",
    "Landesschülervertreter:in",
  ];

  const setSchoolValue = (school: string) => {
    setSchoolId(schools.get(school)!);
    setSchool(school);
  };

  const loadSchools = () => {
    const _stateId = cachedStateToId(state);
    if (_stateId === "") {
      return;
    }
    setStateId(_stateId);

    const _schools = cachedSchools.filter(
      (school) => school.stateId === _stateId
    );
    if (_schools.length === 0) {
      return;
    }
    setSchools(new Map(_schools.map((school) => [school.value, school.id])));
  };

  const onNotFoundSchool = () => {
    setNotFound(!notFound);
  };

  const onNotFoundSchoolSearch = () => {
    if (notFoundSchool === "") {
      loadSchools();
      return;
    }

    const schoolsAll = cachedSchools
      .filter((school) => school.stateId === cachedStateToId(state))
      .filter((school) => school.value.includes(notFoundSchool));

    setSchools(new Map(schoolsAll.map((school) => [school.value, school.id])));
  };

  const onSchoolPress = (school: string) => {
    console.log(school);
    setSchool(school);
    setSchoolId(schools.get(school)!);
    setNotFound(false);
  }

  const notFoundSchoolDecided = () => {
    onSchoolPress("Nicht angeführt");
  }

  if (notFound) {
    return (
      <LSVView header="Schritt 2" scroll full>
        <Text>Information über die Schule</Text>
        <Button title="Zurück" onPress={onNotFoundSchool} variant="outline" />
        <Dropdown
          placeholder="Bundesland"
          value={state}
          setValue={setState}
          items={cachedStates.map((state) => state.value)}
        />
        {state !== "" && (
          <TextInput
            value={notFoundSchool}
            onChangeText={setNotFoundSchool}
            placeholder="Schule"
          />
        )}
        {state !== "" && (
          <Button title="Suchen" onPress={onNotFoundSchoolSearch} variant="filled" />
        )}
        {state !== "" && (
          <Button title="Nicht gefunden!" onPress={notFoundSchoolDecided} variant="filled" />
        )}
        {state !== "" &&
          Array.from(schools.keys()).map((s) => <Button title={s} onPress={() => onSchoolPress(s)} />)}
      </LSVView>
    );
  }

  return (
    <LSVView header="Schritt 2" scroll full>
      <Text>Information über die Schule</Text>
      <Dropdown
        placeholder="Position"
        value={position}
        setValue={setPosition}
        items={positionData}
      />
      <Dropdown
        placeholder="Bundesland"
        value={state}
        setValue={setState}
        items={cachedStates.map((state) => state.value)}
      />
      <Dropdown
        placeholder="Schule"
        value={school}
        setValue={setSchool}
        items={Array.from(schools.keys())}
        onChangeValue={setSchoolValue}
        onOpen={() => loadSchools()}
      />
      <TouchableOpacity onPress={onNotFoundSchool}>
        <Text style={styles.notFound}>Schule nicht gefunden?</Text>
      </TouchableOpacity>
      <View style={styles.buttonContainer}>
        <Button title="Zurück" onPress={togglePage} variant="outline" />
        <Button
          title="Registrieren"
          onPress={handleRegister}
          variant="filled"
        />
      </View>
    </LSVView>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    width: "100%",
    alignItems: "center",
    paddingTop: 30,
    gap: 10,
  },
  notFound: {
    paddingTop: 5,
  },
});
