import { Text } from "react-native";
import React, { useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { useTabContext } from "../../TabContext";
import LSVView from "../../../components/LSVView";
import SipSection from "../../../components/SipSection";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getFirestore,
} from "firebase/firestore";
import { LogType, generateSecureUID, log } from "../../../func/util";

const UserHomeScreen = () => {
  const navigation = useNavigation();
  const { isAdmin, sip, sipSignedUp, user } = useTabContext();

  useEffect(() => {
    if (isAdmin && sip?.isActive) {
      navigation.navigate("AdminSipActive");
    } else if (isAdmin) {
      navigation.navigate("AdminHome");
    } else if (sip?.isActive && sipSignedUp?.verified) {
      navigation.navigate("UserSipActive");
    }
  }, [isAdmin, sip?.isActive, sipSignedUp?.verified]);

  const seeMore = () => {
    navigation.navigate("UserSipSeeMore");
  };

  const signUp = async () => {
    if (!sip) return;

    const db = getFirestore();

    log("UserHomeScreen", LogType.SET, "Signed up for SiP");

    await addDoc(collection(db, "sip", sip.id, "signUps"), {
      userId: user!.id,
      timestamp: new Date(),
      verified: false,
      code: generateSecureUID().substring(0, 6),
    });
  };

  const signOut = async () => {
    if (!sip || !sipSignedUp) return;

    const db = getFirestore();

    log("UserHomeScreen", LogType.SET, "Signed out of SiP");

    await deleteDoc(
      doc(collection(db, "sip", sip.id, "signUps"), sipSignedUp!.id)
    );
  };

  const verify = () => {
    if (!sip || !sipSignedUp || sipSignedUp.verified) return;
    navigation.navigate("UserSipVerify");
  };

  return (
    <LSVView header="LSV App" scroll>
      {sip ? (
        <SipSection
          name="Nächstes SiP"
          title={sip.title}
          date={sip.date}
          description={sip.description}
          firstButtonName="Mehr erfahren"
          firstButtonFunc={seeMore}
          secondButtonName={sipSignedUp ? "Abmelden" : "Anmelden"}
          secondButtonFunc={sipSignedUp ? signOut : signUp}
          showThirdButton={sipSignedUp != null}
          thirdButtonName={sipSignedUp?.verified ? "Verifiziert" : "Verifizieren"}
          thirdButtonFunc={verify}
        />
      ) : (
        <Text>Leider ist derzeit kein SiP verfügbar. (Tipp: Die Website refresht sich von selbst)</Text>
      )}
    </LSVView>
  );
};

export default UserHomeScreen;
