import { StyleSheet, Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import { useTabContext } from "../../../TabContext";
import LSVView from "../../../../components/LSVView";
import { collection, doc, getFirestore, setDoc } from "firebase/firestore";
import { LogType, log } from "../../../../func/util";
import { Button } from "../../../../components/Button";

const UserHoldVoteScreen = () => {
  const { sip, user, curProposal, subProposals } = useTabContext();

  const [response, setResponse] = useState<number | null>(null);

  useEffect(() => {
    setResponse(null);
  }, [sip?.voteFor]);

  if (sip!.voteFor === "") {
    return (
      <LSVView header="Abstimmung">
        <Text>Die Abstimmung wird jeden Moment aktiviert.</Text>
      </LSVView>
    );
  }

  const getTitle = () => {
    if (sip!.voteFor === "__main__") {
      return curProposal!.title + " + Rest";
    }

    const subProposal = subProposals?.find(
      (subProposal) => subProposal.id === sip!.voteFor
    );
    return subProposal!.title;
  };

  const onSend = async (type: number) => {
    const db = getFirestore();
    log("UserHoldVote", LogType.SET, "Vote");

    let docPath = [sip!.id, "proposals", curProposal!.id];

    if (sip!.voteFor !== "__main__") {
      docPath.push("subProposals", sip!.voteFor, "votes");
    } else {
      docPath.push("votes");
    }

    await setDoc(doc(collection(db, "sip", ...docPath), user!.id), {
      value: type,
    })
      .then(() => {
        setResponse(type);
      })
      .catch((error) => {
        log("UserHoldVote", LogType.ERROR, error);
      });
  };

  if (user?.position === "Schüler:in" && user?.isProxy !== true) {
    return (
      <LSVView header={getTitle()}>
        <Text>Nicht-Schülervertreter haben leider kein Stimmrecht!</Text>
      </LSVView>
    );
  }

  return (
    <LSVView header={getTitle()}>
      <Button
        title="Zustimmen"
        onPress={() => onSend(1)}
        variant={response === 1 ? "filled" : "outline"}
      />
      <Button
        title="Enthaltung"
        onPress={() => onSend(0)}
        variant={response === 0 ? "filled" : "outline"}
      />
      <Button
        title="Ablehnen"
        onPress={() => onSend(-1)}
        variant={response === -1 ? "filled" : "outline"}
      />
    </LSVView>
  );
};

export default UserHoldVoteScreen;
