import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { LogType, log } from "./util";

export const handleSignOut = (
  onSignOut: () => void | null,
  onFail: (message: string) => void | null
) => {
  signOut(auth)
    .then(() => {
      log("User Signed Out", LogType.INFO);
      if (onSignOut !== null) {
        onSignOut();
      }
    })
    .catch((error) => {
      if (onFail !== null) {
        onFail(error.message);
      }
    });
};