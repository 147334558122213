import React, { useEffect } from "react";
import { useNavigation } from "@react-navigation/native";
import { useTabContext } from "../../TabContext";
import LSVView from "../../../components/LSVView";
import { Button } from "../../../components/Button";
import SipSection from "../../../components/SipSection";

const AdminHomeScreen = () => {
  const navigation = useNavigation();
  const { isAdmin, sip, sipSignedUp } = useTabContext();

  useEffect(() => {
    if (!isAdmin && sipSignedUp?.verified && sip?.isActive) {
      navigation.navigate("UserSipActive");
    } else if (!isAdmin) {
      navigation.navigate("UserHome");
    } else if (sip?.isActive) {
      navigation.navigate("AdminSipActive");
    }
  }, [isAdmin, sip?.isActive, sipSignedUp?.verified]);

  const editSip = () => {
    navigation.navigate("AdminEditSip");
  };

  const startSip = () => {
    if (sip === null || sip.id === null) {
      alert("Es ist kein SiP verfügbar!");
      return;
    }

    navigation.navigate("AdminSipProposals");
  };

  const verifySip = async () => {
    navigation.navigate("AdminSipVerify");
  };

  return (
    <LSVView header="LSV App">
      {!sip || sip?.done ? (
        <Button title="SiP erstellen" onPress={editSip} variant="filled" />
      ) : (
        <SipSection
          name="Nächstes SiP"
          title={sip.title}
          date={sip.date}
          description={sip.description}
          firstButtonName="Bearbeiten"
          firstButtonFunc={editSip}
          secondButtonName="Starten"
          secondButtonFunc={startSip}
          showThirdButton={true}
          thirdButtonName="Verifizieren"
          thirdButtonFunc={verifySip}
        />
      )}
    </LSVView>
  );
};

export default AdminHomeScreen;
