import React from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';

const Loading = () => {
  return (
    <View style={styles.container}>
      <ActivityIndicator size="large" color="#0000ff" />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white'
  },
  spin: {
    transform: [{ rotate: '45deg' }],
    marginBottom: 20,
    animationDuration: '2s',
    animationIterationCount: 'infinite',
  },
  text: {
    marginTop: 20,
    fontSize: 18,
  }
});

export default Loading;