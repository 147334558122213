import React from "react";
import LSVView from "../../../../components/LSVView";
import { Button } from "../../../../components/Button";
import { useTabContext } from "../../../TabContext";
import {
  average,
  collection,
  doc,
  getAggregateFromServer,
  getFirestore,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { LogType, log } from "../../../../func/util";

const AdminHoldVoteScreen: React.FC<{ goBack: () => void }> = ({ goBack }) => {
  const { sip, curProposal, subProposals } = useTabContext();

  const onVote = async () => {
    const db = getFirestore();

    log("AdminHoldVoteScreen", LogType.SET, "Set Vote");

    await updateDoc(doc(collection(db, "sip"), sip!.id), {
      isVote: !sip!.isVote,
    }).catch((error) => {
      log("AdminHoldVoteScreen", LogType.ERROR, error);
      alert("Admin Sip Hold - Vote: " + error);
      return;
    });
  };

  const onVoteProposal = async (id: string) => {
    try {
      if (sip!.voteFor !== id) {
        await updateSipVoteFor(id);
        return;
      }

      const votes = await calculateVotes(id);
      if (votes == null) return;

      await applyVoteResult(id, votes);
      await resetVoteFor();
    } catch (error) {
      log("AdminHoldVoteScreen", LogType.ERROR, error as string);
    }
  };

  const updateSipVoteFor = async (id: string) => {
    const db = getFirestore();
    log("AdminHoldVoteScreen", LogType.SET, "Set Vote For");
    await updateDoc(doc(collection(db, "sip"), sip!.id), { voteFor: id });
  };

  const calculateVotes = async (id: string) => {
    const db = getFirestore();
    log("AdminHoldVoteScreen", LogType.GET, "Get Votes");
    let queryPath = [sip!.id, "proposals", curProposal!.id, "votes"];

    if (id !== "__main__") {
      queryPath = [...queryPath.slice(0, -1), "subProposals", id, "votes"];
    }

    const snapshot = await getAggregateFromServer(
      query(collection(db, "sip", ...queryPath), where("value", "!=", 0)),
      { votes: average("value") }
    );

    return snapshot.data()?.votes;
  };

  const applyVoteResult = async (id: string, votes: number) => {
    const db = getFirestore();
    log("AdminHoldVoteScreen", LogType.SET, "Set Vote Result");
    let docPath = [sip!.id, "proposals"];

    if (id !== "__main__") {
      docPath = [...docPath, curProposal!.id, "subProposals"];
    } else {
      id = curProposal!.id;
    }

    await updateDoc(doc(collection(db, "sip", ...docPath), id), {
      voteResult: votes * 50 + 50,
    });
  };

  const resetVoteFor = async () => {
    const db = getFirestore();
    log("AdminHoldVoteScreen", LogType.SET, "Reset Vote For");
    await updateDoc(doc(collection(db, "sip"), sip!.id), { voteFor: "" });
  };

  return (
    <LSVView header="Abstimmung" scroll>
      <Button title="Zurück" variant="outline" onPress={goBack} />
      <Button
        title={sip?.isVote ? "Beenden" : "Starten"}
        onPress={onVote}
        variant="filled"
      />
      {sip?.isVote &&
        subProposals.map((subProposal) => (
          <Button
            title={
              subProposal.voteResult === -1
                ? `${subProposal.title} \n${
                    subProposal.id === sip.voteFor ? "Wahl Stoppen" : ""
                  }`
                : `${subProposal.title}\nStimmen ${subProposal.voteResult}%`
            }
            onPress={() => onVoteProposal(subProposal.id)}
            variant={subProposal.voteResult !== -1 ? "filled" : "outline"}
          />
        ))}
      {sip?.isVote && (
        <Button
          title={
            curProposal!.voteResult === -1
              ? `${curProposal!.title} + Rest\n${
                  curProposal!.id === sip!.voteFor ? "Wahl Stoppen" : ""
                }`
              : `${curProposal!.title} + Rest\nStimmen ${
                  curProposal!.voteResult
                }%`
          }
          onPress={() => onVoteProposal("__main__")}
          variant={curProposal!.voteResult !== -1 ? "filled" : "outline"}
        />
      )}
    </LSVView>
  );
};

export default AdminHoldVoteScreen;
