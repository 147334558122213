import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import { KeyboardAvoidingView, StyleSheet, Text, View } from "react-native";
import { UserCredential, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { Button } from "../../components/Button";
import TextInput from "../../components/TextInput";
import LSVView from "../../components/LSVView";
import { LogType, log } from "../../func/util";

const LoginScreen = () => {
  const navigation = useNavigation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential: UserCredential) => {
        const user = userCredential.user;
        log("LoginScreen", LogType.INFO, `Logged in with: ${user.email}`)
      })
      .catch((error) => alert(error.message));
  };

  return (
    <LSVView header="Login" full>
      <TextInput placeholder="Email" value={email} onChangeText={setEmail} />
      <TextInput
        placeholder="Passwort"
        value={password}
        onChangeText={setPassword}
        secureTextEntry
      />
      <View style={styles.button}>
        <Button title="Login" onPress={handleLogin} variant="filled" />
        <Button
          title="Passwort vergessen?"
          onPress={() => navigation.navigate("PasswordForgotten")}
          variant="outline"
        />
      </View>
    </LSVView>
  );
};

export default LoginScreen;

const styles = StyleSheet.create({
  button: {
    marginTop: 30,
    width: "100%",
    alignItems: "center",
  },
});
