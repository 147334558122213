import React, { useState } from "react";
import { ProposalType, useTabContext } from "../../TabContext";
import Loading from "../../../components/Loading";
import LSVView from "../../../components/LSVView";
import { Button } from "../../../components/Button";
import { collection, doc, getFirestore, updateDoc } from "firebase/firestore";
import { LogType, log } from "../../../func/util";
import AdminHoldThreeQuestionsScreen from "./hold/AdminHoldThreeQuestionsScreen";
import AdminHolfRequestEndScreen from "./hold/AdminHolfRequestEndScreen";
import AdminHoldSpeakerListScreen from "./hold/AdminHoldSpeakerListScreen";
import AdminHoldVoteScreen from "./hold/AdminHoldVoteScreen";
import AdminSipVerifyScreen from "./AdminSipVerifyScreen";

const AdminSipHoldScreen = () => {
  const { curProposal, sip, proposals } = useTabContext();

  const [threeQuestions, setThreeQuestions] = useState<boolean>(false);
  const [speakerList, setSpeakerList] = useState<boolean>(false);
  const [vote, setVote] = useState<boolean>(false);
  // TODO: remove, this is a hack
  const [betaVerify, setBetaVerify] = useState<boolean>(false);

  const getNextProposal = async () => {
    let next: ProposalType;

    const sorted = proposals.sort((a, b) => b.ranking - a.ranking);
    const filtered = sorted.filter((p) => p.standard === curProposal!.standard);

    if (filtered[filtered.length - 1].id === curProposal!.id) {
      if (curProposal!.standard) {
        alert("Es gibt keine weiteren Anträge!");

        const db = getFirestore();

        log("AdminSipHoldScreen", LogType.SET, "End SiP");

        await updateDoc(doc(collection(db, "sip"), sip!.id), {
          done: true,
        }).catch((error) => {
          log("AdminSipHoldScreen", LogType.ERROR, error);
          alert("Admin Sip Hold - End SiP: " + error);
          return;
        });
        return;
      }

      next = sorted.find((p) => p.standard)!;
    } else {
      next = filtered[filtered.findIndex((p) => p.id === curProposal!.id) + 1];
    }

    const db = getFirestore();

    log("AdminSipHoldScreen", LogType.SET, "Set next proposal");

    await updateDoc(doc(collection(db, "sip"), sip!.id), {
      currentProposal: next.id,
      isRequestEndOfDebate: "none",
      requestEndOfDebateBy: "",
      isRequestEndOfList: "none",
      requestEndOfListBy: "",
      isSpeakerList: false,
      isSpeakerListStop: false,
      isThreeQuestions: false,
      isVote: false,
      isType1: !next!.standard,
      isType2: next!.standard,
      voteFor: "",
    }).catch((error) => {
      log("AdminSipHoldScreen", LogType.ERROR, error);
      alert("Admin Sip Hold - Next Proposal: " + error);
      return;
    });
  };

  if (!curProposal) {
    return <Loading />;
  }

  if (threeQuestions) {
    return (
      <AdminHoldThreeQuestionsScreen goBack={() => setThreeQuestions(false)} />
    );
  }

  if (sip?.isRequestEndOfDebate === "accepted" || sip?.isRequestEndOfList === "accepted") {
    return <AdminHolfRequestEndScreen />;
  }

  if (speakerList) {
    return <AdminHoldSpeakerListScreen goBack={() => setSpeakerList(false)} />;
  }

  if (vote) {
    return <AdminHoldVoteScreen goBack={() => setVote(false)} />;
  }

  if (betaVerify) {
    return <AdminSipVerifyScreen />;
  }

  return (
    <LSVView header={curProposal.title}>
      <Button title="3 Fragen" onPress={() => setThreeQuestions(true)} />
      <Button title="Rednerliste" onPress={() => setSpeakerList(true)} />
      <Button title="Abstimmung" onPress={() => setVote(true)} />
      <Button title="Verifizieren | Katha klicke hier nicht darauf" onPress={() => setBetaVerify(true)} />
      <Button
        title="Nächster Antrag"
        onPress={getNextProposal}
        variant="filled"
      />
    </LSVView>
  );
};

export default AdminSipHoldScreen;
