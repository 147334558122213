import { StyleSheet, Text, View } from "react-native";
import React, { useEffect } from "react";
import { useTabContext } from "../../TabContext";
import { useNavigation } from "@react-navigation/native";
import {
  collection,
  doc,
  getAggregateFromServer,
  getFirestore,
  sum,
  updateDoc,
} from "firebase/firestore";
import LSVView from "../../../components/LSVView";
import { Button } from "../../../components/Button";
import { LogType, log } from "../../../func/util";

const AdminSipActiveScreen = () => {
  const navigation = useNavigation();
  const { sip, proposals } = useTabContext();

  useEffect(() => {
    if (sip!.isType1 || sip!.isType2) {
      navigation.navigate("AdminSipHold");
    }
  }, [sip?.isType1, sip?.isType2]);

  const finishProposals = async () => {
    try {
      const db = getFirestore();

      log("AdminSipActiveScreen", LogType.SET, "Finish Proposals");

      await updateDoc(doc(collection(db, "sip"), sip!.id), {
        isProposal: false,
      });

      const updateRankingPromises = proposals.map(async (proposal) => {
        try {
          log("AdminSipActiveScreen", LogType.GET, "Get ranking for proposal");

          const snapshot = await getAggregateFromServer(
            collection(db, "sip", sip!.id, "proposals", proposal.id, "ranking"),
            { ranking: sum("value") }
          );

          const ranking = snapshot.data()!.ranking;

          log("AdminSipActiveScreen", LogType.SET, "Set ranking for proposal");

          return updateDoc(
            doc(collection(db, "sip", sip!.id, "proposals"), proposal.id),
            { ranking: ranking }
          );
        } catch (error) {
          log("AdminSipActiveScreen", LogType.ERROR, error as string);
        }
      });

      await Promise.all(updateRankingPromises);
    } catch (error) {
      log("AdminSipActiveScreen", LogType.ERROR, error as string);
    }
  };

  const startSip = async () => {
    const isType1 = proposals.filter((p) => !p.standard).length > 0;
    const currentProposal = proposals
      .filter((p) => (isType1 ? !p.standard : p.standard))
      .sort((p1, p2) => p2.ranking - p1.ranking)[0];

    try {
      const db = getFirestore();

      log("AdminSipActiveScreen", LogType.SET, "Start SiP");

      await updateDoc(
        doc(collection(db, "sip"), sip!.id),
        isType1
          ? {
              isType1: true,
              currentProposal: currentProposal.id,
            }
          : {
              isType2: true,
              currentProposal: currentProposal.id,
            }
      );
    } catch (error) {
      log("AdminSipActiveScreen", LogType.ERROR, error as string);
    }
  };

  if (sip?.isProposal) {
    return (
      <LSVView header="Anträge">
        <Button
          title="Abstimmung Beenden"
          onPress={finishProposals}
          variant="filled"
        />
      </LSVView>
    );
  }

  return (
    <LSVView header="Anträge" scroll>
      <Text style={styles.subheader}>Geschäftsordnung</Text>
      {proposals
        .filter((p) => !p.standard)
        .sort((p1, p2) => p2.ranking - p1.ranking)
        .map((p) => (
          <Button title={`${p.title} \n(${p.ranking} Stimmen)`} key={p.id} />
        ))}
      <Text style={styles.subheader}>Allgemein</Text>
      {proposals
        .filter((p) => p.standard)
        .sort((p1, p2) => p2.ranking - p1.ranking)
        .map((p) => (
          <Button title={`${p.title} \n(${p.ranking} Stimmen)`} key={p.id} />
        ))}
      <View style={styles.buttonContainer}>
        <Button title="SiP Starten" onPress={startSip} variant="filled" />
      </View>
    </LSVView>
  );
};

export default AdminSipActiveScreen;

const styles = StyleSheet.create({
  subheader: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 40,
  },
  buttonContainer: {
    paddingTop: 40,
    width: "100%",
    alignItems: "center",
  },
});
