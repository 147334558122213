import { Text } from "react-native";
import { useTabContext } from "../../TabContext";
import Loading from "../../../components/Loading";
import LSVView from "../../../components/LSVView";
import UserHoldThreeQuestionsScreen from "./hold/UserHoldThreeQuestionsScreen";
import UserHoldRequestEndScreen from "./hold/UserHoldRequestEndScreen";
import UserHoldSpeakerListScreen from "./hold/UserHoldSpeakerListScreen";
import UserHoldVoteScreen from "./hold/UserHoldVoteScreen";

const UserSipHoldScreen = () => {
  const { sip, curProposal } = useTabContext();

  if (!curProposal) {
    return <Loading />;
  }

  if (sip?.isThreeQuestions) {
    return <UserHoldThreeQuestionsScreen />;
  }

  if (sip?.isRequestEndOfDebate === "accepted" || sip?.isRequestEndOfList === "accepted") {
    return <UserHoldRequestEndScreen />;
  }

  if (sip?.isSpeakerList) {
    return <UserHoldSpeakerListScreen />;
  }

  if (sip?.isVote) {
    return <UserHoldVoteScreen />;
  }

  return (
    <LSVView header={curProposal.title}>
      <Text>Sei bereit für die drei Fragen und die Rednerliste.</Text>
    </LSVView>
  );
};

export default UserSipHoldScreen;
