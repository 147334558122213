import { useState } from "react";
import { StyleSheet, Text } from "react-native";
import { useTabContext } from "../../../TabContext";
import { collection, doc, getFirestore, setDoc } from "firebase/firestore";
import { LogType, log } from "../../../../func/util";
import LSVView from "../../../../components/LSVView";
import { Button } from "../../../../components/Button";

const UserThreeQuestions = () => {
  const { sip, user, curProposal } = useTabContext();

  const [threeQuestions, setThreeQuestions] = useState(false);

  const onThreeQuestions = async () => {
    if (!sip || !sip?.isThreeQuestions || !curProposal) {
      return;
    }

    const db = getFirestore();

    log("UserThreeQuestions", LogType.SET, "Three questions");
    await setDoc(
      doc(
        collection(
          db,
          "sip",
          sip!.id,
          "proposals",
          curProposal.id,
          "threeQuestions"
        ),
        user!.id
      ),
      {
        name: user!.name,
        surname: user!.surname,
        timestamp: new Date(),
      }
    )
      .then(() => {
        setThreeQuestions(true);
      })
      .catch((error) => {
        log("UserThreeQuestions", LogType.ERROR, error);
      });
  };

  return (
    <LSVView header="3 Fragen">
      <Text style={styles.descriptionText}>
        Die schnellsten drei Personen dürfen dem:r Antragsteller:in eine Frage
        stellen.
      </Text>
      <Button
        title={threeQuestions ? "Frage Gestellt" : "Frage Stellen"}
        variant={threeQuestions ? "outline" : "filled"}
        onPress={onThreeQuestions}
      />
    </LSVView>
  );
};

export default UserThreeQuestions;

const styles = StyleSheet.create({
  descriptionText: {
    textAlign: "center",
    padding: 10,
  },
});
