import { useState } from "react";
import { useTabContext } from "../../../TabContext";
import {
  DocumentData,
  collection,
  doc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import LSVView from "../../../../components/LSVView";
import { Button } from "../../../../components/Button";
import { View, Text, StyleSheet } from "react-native";
import { LogType, log } from "../../../../func/util";

const AdminHoldThreeQuestionsScreen: React.FC<{
  goBack: () => void;
}> = ({ goBack }) => {
  const { sip, curProposal } = useTabContext();

  const [threeQuestions, setThreeQuestions] = useState<DocumentData[]>([]);

  const onThreeQuestions = async (enable: boolean) => {
    const db = getFirestore();

    log("AdminThreeQuestions", LogType.SET, "Set 3 Questions")

    await updateDoc(doc(collection(db, "sip"), sip!.id), {
      isThreeQuestions: enable,
    }).catch((error) => {
      log("AdminThreeQuestions", LogType.ERROR, error)
      alert("Admin Sip Hold - 3 Questions: " + error);
      return;
    });

    if (enable) {
      return;
    }

    log("AdminThreeQuestions", LogType.GET, "Get 3 Questions")

    await getDocs(
      query(
        collection(
          db,
          "sip",
          sip!.id,
          "proposals",
          curProposal!.id,
          "threeQuestions"
        ),
        orderBy("timestamp", "asc"),
        limit(3)
      )
    )
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          alert("Bitte versuchen sie es erneut!");
          return;
        }

        setThreeQuestions(querySnapshot.docs.map((doc) => doc.data()));
      })
      .catch((error) => {
        log("AdminThreeQuestions", LogType.ERROR, error)
        alert("Admin Sip Hold - 3 Questions: " + error);
      });
  };

  return (
    <LSVView header="3 Fragen">
      <Button title="Zurück" variant="outline" onPress={goBack} />
      <Button
        title={
          sip?.isThreeQuestions ? "Abstimmung beenden" : "Abstimmung Starten"
        }
        onPress={() => onThreeQuestions(!sip?.isThreeQuestions)}
        variant={sip?.isThreeQuestions ? "outline" : "filled"}
      />
      <Text style={styles.subHeader}>3 Fragen:</Text>
      <View style={styles.container}>
        {threeQuestions.map((question) => (
          <Button title={`${question.name} ${question.surname}`} />
        ))}
      </View>
    </LSVView>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    alignItems: "center",
    gap: 10,
    paddingTop: 10,
  },
  subHeader: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: 40,
  },
});

export default AdminHoldThreeQuestionsScreen;