import { useEffect, useState } from "react";
import { useTabContext } from "../../../TabContext";
import {
  collection,
  doc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { LogType, log } from "../../../../func/util";
import LSVView from "../../../../components/LSVView";
import { View, Text, StyleSheet } from "react-native";
import { Button } from "../../../../components/Button";

const UserHoldSpeakerListScreen = () => {
  const { sip, user, curProposal, speakerList } = useTabContext();

  const [isOnSpeakerList, setIsOnSpeakerList] = useState<boolean>(false);
  const [endOfList, setEndOfList] = useState<boolean>(false);
  const [endOfDebate, setEndOfDebate] = useState<boolean>(false);

  useEffect(() => {
    if (!speakerList) {
      return;
    }

    setIsOnSpeakerList(speakerList.some((speaker) => speaker.id === user!.id));
  }, [speakerList]);

  const onSpeakerList = async () => {
    if (!sip || !sip?.isSpeakerList || sip?.isSpeakerListStop || !curProposal) {
      return;
    }

    const db = getFirestore();

    log("UserHoldSpeakerList", LogType.SET, "Speaker list");
    await setDoc(
      doc(
        collection(
          db,
          "sip",
          sip!.id,
          "proposals",
          curProposal.id,
          "speakerList"
        ),
        user!.id
      ),
      {
        name: user!.name,
        surname: user!.surname,
        timestamp: new Date(),
        active: true,
      }
    ).catch((error) => {
      log("UserHoldSpeakerList", LogType.ERROR, error);
    });
  };

  const onSpeakerListRemove = async () => {
    if (!sip || !sip?.isSpeakerList || !curProposal) {
      return;
    }

    const db = getFirestore();
    log("UserHoldSpeakerList", LogType.SET, "Speaker list remove");
    await updateDoc(
      doc(
        collection(
          db,
          "sip",
          sip!.id,
          "proposals",
          curProposal.id,
          "speakerList"
        ),
        user!.id
      ),
      {
        active: false,
      }
    ).catch((error) => {
      log("UserHoldSpeakerList", LogType.ERROR, error);
    });
  };

  const onSpeakerListStop = async () => {
    const db = getFirestore();

    await updateDoc(doc(collection(db, "sip"), sip!.id), {
      isRequestEndOfList: "requested",
      requestEndOfListBy: user!.name + " " + user!.surname,
    }).catch((error) => {
      log("UserHoldSpeakerList", LogType.ERROR, error);
      alert("Admin Sip Hold - Speaker List Stop: " + error);
    }).finally(() => {
      setEndOfList(false);
    });
  };

  const onSpeakerListEndOfDebate = async () => {
    const db = getFirestore();

    await updateDoc(doc(collection(db, "sip"), sip!.id), {
      isRequestEndOfDebate: "requested",
      requestEndOfDebateBy: user!.name + " " + user!.surname,
    }).catch((error) => {
      log("UserHoldSpeakerList", LogType.ERROR, error);
      alert("Admin Sip Hold - Speaker List Stop: " + error);
    }).finally(() => {
      setEndOfDebate(false);
    });
  };

  if (endOfDebate) {
    return (
      <LSVView header="Bestätigung">
        <Text style={styles.importantText}>
          Bist du dir sicher, den Schluss der Debatte zu beantragen?
        </Text>
        <Text style={styles.importantText}>
          Information: Bei deinem Antrag zum Schluss der Debatte wird dein Name
          übermittelt.
        </Text>
        <Button title="Ja" onPress={onSpeakerListEndOfDebate} />
        <Button title="Nein" onPress={() => setEndOfDebate(false)} />
      </LSVView>
    );
  }

  if (endOfList) {
    return (
      <LSVView header="Bestätigung">
        <Text style={styles.importantText}>
          Bist du dir sicher, den Schluss der Rednerliste zu beantragen?
        </Text>
        <Text style={styles.importantText}>
          Information: Bei deinem Antrag zum Schluss der Rednerliste wird dein
          Name übermittelt.
        </Text>
        <Button title="Ja" onPress={onSpeakerListStop} />
        <Button title="Nein" onPress={() => setEndOfList(false)} />
      </LSVView>
    );
  }

  return (
    <LSVView header="Rednerliste" scroll>
      <Text style={styles.descriptionText}>
        Stelle dich auf die Rednerliste, um den Antrag zu erweitern bzw.
        abzuändern.
      </Text>
      <View
        style={
          sip?.isSpeakerListStop
            ? isOnSpeakerList
              ? styles.signUpActivated
              : styles.signUpDeactivated
            : styles.signUpActivated
        }
      >
        <Button
          title={isOnSpeakerList ? "Entfernen" : "Melden"}
          variant={isOnSpeakerList ? "outline" : "filled"}
          onPress={isOnSpeakerList ? onSpeakerListRemove : onSpeakerList}
        />
      </View>
      {!sip?.isSpeakerListStop && sip?.isRequestEndOfList === "none" && (
        <Button title="Schluss der Rednerliste" onPress={() => setEndOfList(true)} />
      )}
      {sip?.isRequestEndOfDebate === "none" && (
        <Button
          title="Schluss der Debatte"
          onPress={() => setEndOfDebate(true)}
        />
      )}
      <Text style={styles.subHeader}>Rednerliste:</Text>
      <View style={styles.container}>
        {speakerList?.map((speaker) => (
          <Button
            key={speaker.id}
            title={`${speaker.name} ${speaker.surname}`}
          />
        ))}
      </View>
    </LSVView>
  );
};

export default UserHoldSpeakerListScreen;

const styles = StyleSheet.create({
  descriptionText: {
    textAlign: "center",
    padding: 10,
  },
  importantText: {
    textAlign: "center",
    padding: 10,
    fontWeight: "bold",
  },
  container: {
    width: "100%",
    alignItems: "center",
    gap: 10,
    paddingTop: 20,
  },
  subHeader: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: 40,
  },
  signUpDeactivated: {
    opacity: 0.5,
    alignItems: "center",
    width: "100%",
  },
  signUpActivated: {
    alignItems: "center",
    width: "100%",
  },
});
