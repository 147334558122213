import {
  and,
  collection,
  doc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { auth } from "../firebase";
import { LogType, log } from "../func/util";

export interface UserType {
  id: string;
  name: string;
  surname: string;
  position: string;
  state: string;
  school: string;
  isProxy?: boolean;
}

export interface SipType {
  id: string;
  title: string;
  date: string;
  startTime: string;
  endTime: string;
  location: string;
  description: string;
  state: string;
  isActive: boolean;
  isProposal: boolean;
  isSpeakerList: boolean;
  isSpeakerListStop: boolean;
  isRequestEndOfDebate: string;
  requestEndOfDebateBy: string;
  isRequestEndOfList: string;
  requestEndOfListBy: string;
  isThreeQuestions: boolean;
  currentProposal: string;
  isVote: boolean;
  isType1: boolean;
  isType2: boolean;
  done: boolean;
  voteFor: string;
}

export interface SipSignedUpType {
  id: string;
  userId: string;
  verified: boolean;
  code: string;
}

export interface ProposalType {
  id: string;
  title: string;
  standard: boolean;
  ranking: number;
  voteResult: number;
}

export interface SpeakerType {
  id: string;
  name: string;
  surname: string;
  timestamp: Date;
  active: boolean;
}

export interface SubProposalType {
  id: string;
  title: string;
  timestamp: Date;
  voteResult: number;
}

export interface TabContextType {
  isAdmin: boolean;
  user: UserType | null;
  sip: SipType | null;
  sipSignedUp: SipSignedUpType | null;
  proposals: ProposalType[];
  curProposal: ProposalType | null;
  speakerList: SpeakerType[];
  subProposals: SubProposalType[];
}

const TabContext = createContext<TabContextType | undefined>(undefined);

export const useTabContext = () => {
  const context = useContext(TabContext);
  if (!context) {
    throw new Error("useTabContext must be used within a TabProvider");
  }
  return context;
};

export const TabProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<UserType | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [sip, setSip] = useState<SipType | null>(null);
  const [sipSignedUp, setSipSignedUp] = useState<SipSignedUpType | null>(null);
  const [proposals, setProposals] = useState<ProposalType[]>([]);
  const [curProposal, setCurProposal] = useState<ProposalType | null>(null);
  const [speakerList, setSpeakerList] = useState<SpeakerType[]>([]);
  const [subProposals, setSubProposals] = useState<SubProposalType[]>([]);

  useEffect(() => {
    const db = getFirestore();

    const unsubscribe = onSnapshot(
      doc(db, "users", auth.currentUser!.uid),
      (doc) => {
        log("Context - Get user", LogType.GET);
        if (doc.exists()) {
          setUser({ ...doc.data(), id: doc.id } as UserType);
        } else {
          setUser(null);
        }
      },
      (error) => {
        log("Context - Get user", LogType.ERROR, error.message);
      }
    );

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const db = getFirestore();

    if (!user?.state) {
      setIsAdmin(false);
      return;
    }

    const unsubscribe = onSnapshot(
      doc(db, "states", user?.state),
      (doc) => {
        log("Context - Get admin", LogType.GET);
        if (doc.exists()) {
          setIsAdmin(doc.data()?.manager === auth.currentUser!.uid);
        } else {
          setIsAdmin(false);
        }
      },
      (error) => {
        log("Context - Get admin", LogType.ERROR, error.message);
      }
    );

    return () => unsubscribe();
  }, [user?.state]);

  useEffect(() => {
    const db = getFirestore();

    if (!user?.state) {
      setSip(null);
      return;
    }

    const unsubscribe = onSnapshot(
      query(
        collection(db, "sip"),
        and(where("state", "==", user.state), where("done", "==", false))
      ),
      (snapshot) => {
        log("Context - Get sip", LogType.GET);
        if (snapshot.docs.length > 0) {
          const sipsData = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setSip(sipsData[0] as SipType);
        } else {
          setSip(null);
        }
      },
      (error) => {
        log("Context - Get sip", LogType.ERROR, error.message);
      }
    );

    return () => unsubscribe();
  }, [user?.state]);

  useEffect(() => {
    const db = getFirestore();

    if (!sip) {
      setSipSignedUp(null);
      return;
    }

    const unsubscribe = onSnapshot(
      query(
        collection(db, "sip", sip.id, "signUps"),
        where("userId", "==", auth.currentUser!.uid)
      ),
      (snapshot) => {
        log("Context - Get sip signed up", LogType.GET);
        if (snapshot.docs.length > 0) {
          const sipSignUpData = {
            ...snapshot.docs[0].data(),
            id: snapshot.docs[0].id,
          };
          setSipSignedUp(sipSignUpData as SipSignedUpType);
        } else {
          setSipSignedUp(null);
        }
      },
      (error) => {
        log("Context - Get sip signed up", LogType.ERROR, error.message);
      }
    );

    return () => unsubscribe();
  }, [sip?.id]);

  useEffect(() => {
    const db = getFirestore();

    if (!sip) {
      setProposals([]);
      return;
    }

    const unsubscribe = onSnapshot(
      query(collection(db, "sip", sip.id, "proposals")),
      (snapshot) => {
        log("Context - Get proposals", LogType.GET);
        if (snapshot.docs.length > 0) {
          const proposalsData = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setProposals(proposalsData as ProposalType[]);
        } else {
          setProposals([]);
        }
      },
      (error) => {
        log("Context - Get proposals", LogType.ERROR, error.message);
      }
    );

    return () => unsubscribe();
  }, [sip?.isActive]);

  useEffect(() => {
    setCurProposal(
      proposals.find((p) => p.id === sip?.currentProposal) || null
    );
  }, [sip?.currentProposal, proposals]);

  useEffect(() => {
    const db = getFirestore();

    if (!sip || !sip?.isSpeakerList || !curProposal) {
      setSpeakerList([]);
      return;
    }

    const unsubscribe = onSnapshot(
      query(
        collection(
          db,
          "sip",
          sip.id,
          "proposals",
          curProposal.id,
          "speakerList"
        ),
        where("active", "==", true)
      ),
      (snapshot) => {
        log("Context - Get speaker list", LogType.GET);
        if (snapshot.docs.length > 0) {
          const speakerListData: SpeakerType[] = snapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name,
            surname: doc.data().surname,
            timestamp: new Date(
              doc.data().timestamp.seconds * 1000 +
                doc.data().timestamp.nanoseconds / 1000000
            ),
            active: doc.data().active,
          }));
          setSpeakerList(
            speakerListData.sort(
              (a, b) => a.timestamp.getTime() - b.timestamp.getTime()
            )
          );
        } else {
          setSpeakerList([]);
        }
      },
      (error) => {
        log("Context - Get speaker list", LogType.ERROR, error.message);
      }
    );

    return () => unsubscribe();
  }, [curProposal, sip?.isSpeakerList]);

  useEffect(() => {
    const db = getFirestore();

    if (!sip || !curProposal) {
      setSubProposals([]);
      return;
    }

    const unsubscribe = onSnapshot(
      query(
        collection(
          db,
          "sip",
          sip.id,
          "proposals",
          curProposal.id,
          "subProposals"
        )
      ),
      (snapshot) => {
        log("Context - Get sub proposals", LogType.GET);
        if (snapshot.docs.length > 0) {
          const subProposalsData: SubProposalType[] = snapshot.docs.map(
            (doc) => ({
              id: doc.id,
              title: doc.data().title,
              timestamp: new Date(
                doc.data().timestamp.seconds * 1000 +
                  doc.data().timestamp.nanoseconds / 1000000
              ),
              voteResult: doc.data().voteResult,
            })
          );
          setSubProposals(
            subProposalsData.sort(
              (a, b) => a.timestamp.getTime() - b.timestamp.getTime()
            )
          );
        } else {
          setSubProposals([]);
        }
      },
      (error) => {
        log("Context - Get sub proposals", LogType.ERROR, error.message);
      }
    );

    return () => unsubscribe();
  }, [curProposal]);

  return (
    <TabContext.Provider
      value={{
        user,
        isAdmin,
        sip,
        sipSignedUp,
        proposals,
        curProposal,
        speakerList,
        subProposals,
      }}
    >
      {children}
    </TabContext.Provider>
  );
};
