import { TextInputProps as _TextInputProps, TextInput as _TextInput, View, StyleSheet } from "react-native";

interface TextInputProps extends _TextInputProps {
  value: string;
  onChangeText: (text: string) => void;
}

const TextInput: React.FC<TextInputProps> = ({
  value,
  onChangeText,
  ...otherProps
}) => {
  return (
    <View style={styles.inputContainer}>
      <_TextInput
        value={value}
        onChangeText={onChangeText}
        style={styles.input}
        {...otherProps}
      />
    </View>
  );
};

export default TextInput;

const styles = StyleSheet.create({
  inputContainer: {
    width: "80%",
    maxWidth: 500,
  },
  input: {
    backgroundColor: "white",
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 10,
    marginTop: 10,
    fontSize: 16,
  },
});
