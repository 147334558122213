import { StyleSheet } from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Ionicons } from "@expo/vector-icons";
import HomeScreen from "../home/HomeScreen";
import ProfileScreen from "../profile/ProfileScreen";
import { TabProvider } from "../TabContext";

export type TabsTabParamList = {
  Home: undefined;
  Profile: undefined;
};

declare global {
  namespace ReactNavigation {
    interface RootParamList extends TabsTabParamList {}
  }
}

const Tab = createBottomTabNavigator<TabsTabParamList>();

const TabsScreen = () => {
  return (
    <TabProvider>
      <Tab.Navigator
        screenOptions={({ route }) => ({
          tabBarIcon: ({ focused, color, size }) => {
            let iconName: "home" | "home-outline" | "person" | "person-outline";

            if (route.name === "Home") {
              iconName = focused ? "home" : "home-outline";
            } else {
              iconName = focused ? "person" : "person-outline";
            }

            return <Ionicons name={iconName} size={size} color={"black"} />;
          },
        })}
      >
        <Tab.Screen
          name="Home"
          component={HomeScreen}
          options={{
            headerShown: false,
            tabBarLabel: "Hauptmenü",
            tabBarLabelStyle: styles.tabBarLabel,
          }}
          listeners={({ navigation, route }) => ({
            tabPress: (e) => {
              e.preventDefault();
              if (!navigation.isFocused()) {
                navigation.navigate(route.name);
              }
            },
          })}
        />
        <Tab.Screen
          name="Profile"
          component={ProfileScreen}
          options={{
            headerShown: false,
            tabBarLabel: "Profil",
            tabBarLabelStyle: styles.tabBarLabel,
          }}
          listeners={({ navigation, route }) => ({
            tabPress: (e) => {
              e.preventDefault();
              if (!navigation.isFocused()) {
                navigation.navigate(route.name);
              }
            },
          })}
        />
      </Tab.Navigator>
    </TabProvider>
  );
};

export default TabsScreen;

const styles = StyleSheet.create({
  tabBarLabel: {
    color: "black",
  },
});
