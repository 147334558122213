import React, { useState } from "react";
import { useTabContext } from "../../../TabContext";
import { collection, doc, getFirestore, setDoc } from "firebase/firestore";
import { LogType, log } from "../../../../func/util";
import LSVView from "../../../../components/LSVView";
import { Button } from "../../../../components/Button";
import { Text } from "react-native";

const UserHoldRequestEndScreen = () => {
  const { sip, user, curProposal } = useTabContext();

  const [response, setResponse] = useState<number | null>(null);

  const onSend = async (type: number) => {
    const db = getFirestore();

    log("UserHoldRequestEnd", LogType.SET, "Vote");
    await setDoc(
      doc(
        collection(
          db,
          "sip",
          sip!.id,
          "proposals",
          curProposal!.id,
          sip!.isRequestEndOfDebate === "accepted"
            ? "requestEndOfDebate"
            : "requestEndOfList"
        ),
        user!.id
      ),
      {
        value: type,
      }
    )
      .then(() => {
        setResponse(type);
      })
      .catch((error) => {
        log("UserHoldRequestEnd", LogType.ERROR, error.message);
      });
  };

  return (
    <LSVView
      header={
        sip?.isRequestEndOfDebate === "accepted"
          ? "Ende der Debatte"
          : "Schluss der Rednerliste"
      }
    >
      <Text>
        Anfrage von{' '}
        {sip?.isRequestEndOfDebate === "accepted"
          ? sip?.requestEndOfDebateBy
          : sip?.requestEndOfListBy}
      </Text>
      <Button
        title="Zustimmen"
        onPress={() => onSend(1)}
        variant={response === 1 ? "filled" : "outline"}
      />
      <Button
        title="Enthaltung"
        onPress={() => onSend(0)}
        variant={response === 0 ? "filled" : "outline"}
      />
      <Button
        title="Ablehnen"
        onPress={() => onSend(-1)}
        variant={response === -1 ? "filled" : "outline"}
      />
    </LSVView>
  );
};

export default UserHoldRequestEndScreen;
