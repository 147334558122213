import { StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import { useTabContext } from "../../../TabContext";
import {
  average,
  collection,
  doc,
  getAggregateFromServer,
  getFirestore,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { LogType, log } from "../../../../func/util";
import LSVView from "../../../../components/LSVView";
import { Button } from "../../../../components/Button";

const AdminHolfRequestEndScreen = () => {
  const { sip, curProposal } = useTabContext();

  const [result, setResult] = useState<number>(-1);

  const onPoll = async () => {
    const db = getFirestore();

    log("AdminHolfRequestEndScreen", LogType.GET, "Get Poll");

    await getAggregateFromServer(
      query(
        collection(
          db,
          "sip",
          sip!.id,
          "proposals",
          curProposal!.id,
          sip!.isRequestEndOfDebate === "accepted"
            ? "requestEndOfDebate"
            : "requestEndOfList"
        ),
        where("value", "!=", 0)
      ),
      {
        votes: average("value"),
      }
    )
      .then((snapshot) => {
        setResult(snapshot.data()!.votes! * 50 + 50);
      })
      .catch((error) => {
        log("AdminHolfRequestEndScreen", LogType.ERROR, error);
        alert("Admin Sip Hold - Poll: " + error);
        return;
      });
  };

  const onEnd = async () => {
    const db = getFirestore();

    log("AdminHolfRequestEndScreen", LogType.SET, "Set Request End");

    await updateDoc(doc(collection(db, "sip"), sip!.id), {
      isRequestEndOfDebate: "none",
      isRequestEndOfList: "none",
    }).catch((error) => {
      log("AdminHolfRequestEndScreen", LogType.ERROR, error);
      alert("Admin Sip Hold - End: " + error);
      return;
    });
  };

  return (
    <LSVView
      header={
        sip?.isRequestEndOfDebate === "accepted"
          ? "Ende der Debatte"
          : "Schluss der Rednerliste"
      }
    >
      <Text>
        Von:{" "}
        {sip?.isRequestEndOfDebate === "accepted"
          ? sip?.requestEndOfDebateBy
          : sip?.requestEndOfListBy}
      </Text>
      <Button
        title={result == -1 ? "Wahl Beenden" : "Schließen"}
        onPress={result == -1 ? onPoll : onEnd}
        variant="filled"
      />
      {result != -1 && (
        <Text style={styles.subHeader}>Ergebnis: {result}%</Text>
      )}
    </LSVView>
  );
};

export default AdminHolfRequestEndScreen;

const styles = StyleSheet.create({
  subHeader: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: 40,
  },
});
