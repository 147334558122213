import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { Button } from './Button';

const SipSection: React.FC<{
  name: string;
  title: string;
  date: string;
  description: string;
  firstButtonName: string;
  firstButtonFunc: () => void;
  secondButtonName: string;
  secondButtonFunc: () => void;
  showThirdButton: boolean;
  thirdButtonName: string;
  thirdButtonFunc: () => void;
}> = ({
  name,
  title,
  date,
  description,
  firstButtonName,
  firstButtonFunc,
  secondButtonName,
  secondButtonFunc,
  showThirdButton,
  thirdButtonName,
  thirdButtonFunc,
}) => {
  return (
    <View style={styles.container}>
      <Text style={styles.header}>{name}</Text>
      <View style={styles.section}>
        <View style={styles.sectionHeaderRow}>
          <Text style={styles.sectionTitle}>{title}</Text>
          <Text style={styles.sectionDate}>{date}</Text>
        </View>
        <View style={styles.sectionBody}>
          <Text style={styles.sectionDescription}>{description}</Text>
        </View>
        <View style={styles.sectionEnd}>
          <Button title={firstButtonName} onPress={firstButtonFunc} variant="outline"/>
          {showThirdButton && (
            <Button title={thirdButtonName} onPress={thirdButtonFunc} variant="outline"/>
          )}
          <Button title={secondButtonName} onPress={secondButtonFunc} variant="filled"/>
        </View>
      </View>
    </View>
  )
}

export default SipSection

const styles = StyleSheet.create({
  container: {
    width: "100%",
    alignSelf: "center",
    maxWidth: 600,
    paddingBottom: 20,
  },
  header: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
    alignSelf: "flex-start",
  },
  section: {
    width: "100%",
    alignSelf: "center",
    maxWidth: 600,
    paddingHorizontal: 10,
    padding: 20,
    borderRadius: 10,
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "black",
    marginBottom: 20,
  },
  sectionHeaderRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
    alignSelf: "flex-start",
  },
  sectionDate: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 10,
    alignSelf: "flex-start",
  },
  sectionDescription: {
    fontSize: 16,
    marginBottom: 10,
    maxWidth: 500,
    alignSelf: "center",
  },
  sectionBody: {
    flex: 1,
    width: "100%",
    maxWidth: 500,
    alignItems: "center",
  },
  sectionEnd: {
    width: "100%",
    alignItems: "center",
    gap: 10,
  },
})