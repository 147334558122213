import React, { Dispatch, SetStateAction } from "react";
import DragList, { DragListRenderItemInfo } from "react-native-draglist";
import { Button } from "./Button";
import {
  Platform,
  ScrollView,
  View,
  StyleSheet,
  TouchableOpacity,
  Text,
} from "react-native";

const DraggableList = <T,>({
  data,
  setData,
  keyExtractor,
}: {
  data: T[];
  setData: Dispatch<SetStateAction<T[]>>;
  keyExtractor: (item: T) => string;
}) => {
  function renderItem(info: DragListRenderItemInfo<T>) {
    const { item, onDragStart, onDragEnd } = info;

    return (
      <View style={styles.dragItem}>
      <Button
        title={keyExtractor(item)}
        onPressIn={() => {
          onDragStart();
        }}
        onPressOut={onDragEnd}
        variant="outline"
      />
      </View>
    );
  }

  const onReordered = (fromIndex: number, toIndex: number) => {
    const copy = [...data];
    const removed = copy.splice(fromIndex, 1);
    copy.splice(toIndex, 0, removed[0]);
    setData(copy);
  };

  if (Platform.OS === "ios" || Platform.OS === "android") {
    return (
      <View style={styles.dragContainer}>
        <DragList
          data={data}
          keyExtractor={keyExtractor}
          onReordered={onReordered}
          renderItem={renderItem}
        />
      </View>
    );
  }

  const moveUp = (index: number) => {
    if (index === 0) return;
    const newItems = [...data];
    [newItems[index - 1], newItems[index]] = [
      newItems[index],
      newItems[index - 1],
    ];
    setData(newItems);
  };

  const moveDown = (index: number) => {
    if (index === data.length - 1) return;
    const newItems = [...data];
    [newItems[index + 1], newItems[index]] = [
      newItems[index],
      newItems[index + 1],
    ];
    setData(newItems);
  };

  return (
    <View style={styles.container}>
      {data.map((item, index) => (
        <View key={index} style={styles.itemContainer}>
          <Button title={keyExtractor(item)} variant="outline" />
          <TouchableOpacity
            onPress={() => moveUp(index)}
            style={styles.button}
            disabled={index === 0}
          >
            <Text style={index === 0 ? styles.disabledArrow : styles.arrow}>
              ↑
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => moveDown(index)}
            style={styles.button}
            disabled={index === data.length - 1}
          >
            <Text
              style={
                index === data.length - 1 ? styles.disabledArrow : styles.arrow
              }
            >
              ↓
            </Text>
          </TouchableOpacity>
        </View>
      ))}
    </View>
  );
};

export default DraggableList;

const styles = StyleSheet.create({
  dragContainer: {
    maxHeight: 400,
    width: "100%",
    alignItems: "center",
  },
  dragItem: {
    width: 300, 
    alignSelf: "center"
  },
  container: {
    width: "100%",
  },
  itemContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    gap: 10,
    paddingHorizontal: 30,
  },
  button: {
    width: 20,
    height: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  arrow: {
    opacity: 1,
  },
  disabledArrow: {
    opacity: 0.3,
  },
});
