import { KeyboardAvoidingView, StyleSheet, Text, View } from 'react-native'
import { useState } from 'react'
import TextInput from '../../components/TextInput'
import { Button } from '../../components/Button'
import { sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '../../firebase'
import LSVView from '../../components/LSVView'
import { LogType, log } from '../../func/util'

const PasswordForgottenScreen = () => {
  const [email, setEmail] = useState("");

  const handleSendCode = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        log("PasswordForgottenScreen", LogType.INFO, "Password reset email sent");
        alert("Email wurde gesendet");
      })
      .catch((error) => alert(error.message));
  };
  
  return (
    <LSVView header="Neues Passwort" full>
      <Text style={styles.subheader}>
        Bitte gib deine E-Mail-Adresse ein, um eine Email mit einem Link zu
        erhalten, um dein Passwort zurückzusetzen.
      </Text>
      <TextInput
        placeholder="Email"
        value={email}
        onChangeText={setEmail}
      />
      <View style={styles.button}>
        <Button title="Reset Email senden" onPress={handleSendCode} variant="filled" />
      </View>
    </LSVView>
  )
}

export default PasswordForgottenScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    padding: 20,
  },
  header: {
    fontSize: 36,
    fontWeight: "bold",
    marginBottom: 30,
  },
  subheader: {
    fontSize: 16,
    marginBottom: 30,
    textAlign: "center",
    width: "80%",
    maxWidth: 300,
  },
  button: {
    marginTop: 30,
    width: "100%",
    alignItems: "center",
  },
});