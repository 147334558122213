import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import WelcomeScreen from "./screens/start/WelcomeScreen";
import TabsScreen from "./screens/start/TabsScreen";
import LoginScreen from "./screens/start/LoginScreen";
import SignupScreen from "./screens/start/SignupScreen";
import PasswordForgottenScreen from "./screens/start/PasswordForgottenScreen";

export type RootStackParamList = {
  Welcome: undefined;
  Login: undefined;
  Signup: undefined;
  PasswordForgotten: undefined;
  Tabs: undefined;
};

declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }
}

const Stack = createNativeStackNavigator<RootStackParamList>();

export default function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator>
        <Stack.Screen
          options={{ headerShown: false }}
          name="Welcome"
          component={WelcomeScreen}
        />
        <Stack.Screen
          options={{ headerTitle: "Login" }}
          name="Login"
          component={LoginScreen}
        />
        <Stack.Screen
          options={{ headerTitle: "Registrieren" }}
          name="Signup"
          component={SignupScreen}
        />
        <Stack.Screen
          options={{ headerTitle: "Passwort vergessen?" }}
          name="PasswordForgotten"
          component={PasswordForgottenScreen}
        />
        <Stack.Screen
          options={{ headerShown: false, gestureEnabled: false }}
          name="Tabs"
          component={TabsScreen}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
