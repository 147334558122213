import { Text } from 'react-native'
import React, { useEffect } from 'react'
import LSVView from '../../../components/LSVView'
import { useTabContext } from '../../TabContext'
import { useNavigation } from '@react-navigation/native'

const UserSipActiveScreen = () => {
  const navigation = useNavigation();
  const { sip } = useTabContext();

  useEffect(() => {
    if (sip!.isProposal) {
      navigation.navigate('UserSipProposals');
    }
    if (sip?.isType1 || sip?.isType2) {
      navigation.navigate('UserSipHold');
    }
  }, []);
  
  useEffect(() => {
    if (sip?.isType1 || sip?.isType2) {
      navigation.navigate('UserSipHold');
    }
  }, [sip?.isType1, sip?.isType2]);

  return (
    <LSVView header={sip!.title}>
      <Text>Das SiP wird jeden Augenblick starten!</Text>
    </LSVView>
  )
}

export default UserSipActiveScreen;