import { StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import { useTabContext } from "../../../TabContext";
import {
  collection,
  doc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { LogType, log } from "../../../../func/util";
import LSVView from "../../../../components/LSVView";
import { Button } from "../../../../components/Button";
import TextInput from "../../../../components/TextInput";

const AdminHoldSpeakerListScreen: React.FC<{
  goBack: () => void;
}> = ({ goBack }) => {
  const { sip, speakerList, curProposal } = useTabContext();

  const [subProposal, setSubProposal] = useState<boolean>(false);
  const [subProposalTitle, setSubProposalTitle] = useState<string>("");

  const onSpeakerList = async (enable: boolean) => {
    const db = getFirestore();

    log("AdminHoldSpeakerListScreen", LogType.SET, "Set Speaker List");

    await updateDoc(doc(collection(db, "sip"), sip!.id), {
      isSpeakerList: enable,
    }).catch((error) => {
      log("AdminHoldSpeakerListScreen", LogType.ERROR, error);
      alert("Admin Sip Hold - Speaker List: " + error);
      return;
    });
  };

  const onSpeakerListStop = async (enable: boolean) => {
    const db = getFirestore();

    log("AdminHoldSpeakerListScreen", LogType.SET, "Set Speaker List Stop");

    await updateDoc(doc(collection(db, "sip"), sip!.id), {
      isSpeakerListStop: enable,
    }).catch((error) => {
      log("AdminHoldSpeakerListScreen", LogType.ERROR, error);
      alert("Admin Sip Hold - Speaker List Stop: " + error);
      return;
    });
  };

  const onSpeakerRemove = async (speakerId: string) => {
    const db = getFirestore();

    log("AdminHoldSpeakerListScreen", LogType.SET, "Remove Speaker");

    await updateDoc(
      doc(
        collection(
          db,
          "sip",
          sip!.id,
          "proposals",
          curProposal!.id,
          "speakerList"
        ),
        speakerId
      ),
      {
        active: false,
      }
    ).catch((error) => {
      log("AdminHoldSpeakerListScreen", LogType.ERROR, error);
      alert("Admin Sip Hold - Speaker List Remove: " + error);
      return;
    });
  };

  const onSubProposal = async () => {
    if (subProposalTitle === "") {
      alert("Bitte geben sie einen Titel ein!");
      return;
    }

    const db = getFirestore();

    log("AdminHoldSpeakerListScreen", LogType.SET, "Add Sub Proposal");

    await setDoc(
      doc(
        collection(
          db,
          "sip",
          sip!.id,
          "proposals",
          curProposal!.id,
          "subProposals"
        )
      ),
      {
        title: subProposalTitle,
        timestamp: new Date(),
        voteResult: -1,
      }
    )
      .then((v) => {
        setSubProposalTitle("");
        setSubProposal(false);
      })
      .catch((error) => {
        log("AdminHoldSpeakerListScreen", LogType.ERROR, error);
        alert("Admin Sip Hold - Sub Proposal: " + error);
        return;
      });
  };

  const acceptRequest = async (type: string) => {
    const db = getFirestore();

    log("AdminHoldSpeakerListScreen", LogType.SET, "Accept Request");

    await updateDoc(doc(collection(db, "sip"), sip!.id), {
      [type]: "accepted",
    }).catch((error) => {
      log("AdminHoldSpeakerListScreen", LogType.ERROR, error);
      alert("Admin Sip Hold - Accept Request: " + error);
      return;
    });
  }

  return (
    <LSVView header="Rednerliste" scroll>
      <Button title="Zurück" variant="outline" onPress={goBack} />
      {sip?.isRequestEndOfDebate === "requested" && (
        <Button
          title={"Anfrage - Debatte Beenden - von " + sip?.requestEndOfDebateBy}
          onPress={() => acceptRequest("isRequestEndOfDebate")}
          variant="filled"
        />
      )}
      {sip?.isRequestEndOfList === "requested" && (
        <Button
          title={"Anfrage - Rednerliste Beenden - von " + sip?.requestEndOfListBy}
          onPress={() => acceptRequest("isRequestEndOfList")}
          variant="filled"
        />
      )}
      <Button
        title={
          sip?.isSpeakerList ? "Rednerliste Beenden" : "Rednerliste Starten"
        }
        onPress={() => onSpeakerList(!sip?.isSpeakerList)}
        variant={sip?.isSpeakerList ? "outline" : "filled"}
      />
      <Button
        title={
          sip?.isSpeakerListStop
            ? "Rednerliste Aktivieren"
            : "Rednerliste Stoppen"
        }
        onPress={() => onSpeakerListStop(!sip?.isSpeakerListStop)}
      />
      <Button
        title="Erweiterungsantrag / Abänderungsantrag"
        onPress={() => setSubProposal(!subProposal)}
      />
      {subProposal && (
        <TextInput
          placeholder="Antragstitel"
          value={subProposalTitle}
          onChangeText={setSubProposalTitle}
        />
      )}
      {subProposal && (
        <Button
          title="Antrag hinzufügen"
          onPress={onSubProposal}
          variant="filled"
        />
      )}
      <Text style={styles.subHeader}>Rednerliste:</Text>
      <View style={styles.container}>
        {speakerList?.map((speaker) => (
          <Button
            key={speaker.id}
            title={`${speaker.name} ${speaker.surname}`}
            onPress={() => onSpeakerRemove(speaker.id)}
          />
        ))}
      </View>
    </LSVView>
  );
};

export default AdminHoldSpeakerListScreen;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    alignItems: "center",
    gap: 10,
    paddingTop: 10,
  },
  subHeader: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: 40,
  },
});
